import React, {useEffect, useState, useRef, useMemo} from 'react';
import { useSelector, useDispatch } from "react-redux";

import DataTable from 'react-data-table-component';
import {fetch_products, delete_product, update_product} from '../actions'

const __ = txt => (txt)


const Products = () => {

    const dispatch = useDispatch();

    const products = useSelector(state => state.products.list)
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [showModal, setShowModal] = useState(false)

    const filteredData = products.filter(({name,model})=>(
        name && model && (name.toLowerCase().includes(filterText.toLowerCase()) || model.toLowerCase().includes(filterText.toLowerCase()))))
        .map(pp => ({...pp, yag_value:0}))

    useEffect(()=>{
        dispatch(fetch_products())
    },[dispatch])

    // implement functions

    const fnDelete = row => {
        if(confirm(__('delete selected product?') + `[${row.name}]`))
            dispatch(delete_product(row._id))
    }
    //  table columns
    const columns = [
        {
          name: __('Name'),
          selector: 'name',
          sortable: true,
        }, {
          name: __('Model'),
          selector: 'model',
          sortable: true,
        }, {
            name: __('Brand'),
            selector: 'brand',
            sortable: true,
        }, {
          name: '',
          selector: 'action',
          cell: row => (<>
            <button className="btn btn-link" onClick={()=>{setShowModal(row)}}><IconPencil /></button>
            <button className="btn btn-link" onClick={()=>{fnDelete(row)}}><IconTrash /></button>
          </>)
        }
      ];


    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
          if (filterText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterText('');
          }
        };

        return <FilterComponent
                    onFilter={e => setFilterText(e.target.value)}
                    onClear={handleClear}
                    filterText={filterText}
                    openModal={()=>setShowModal(true)} />;
    }, [filterText, resetPaginationToggle]);


    return (
        <>
            <div className="card">
                <DataTable
                    title={__('Products')}
                    columns={columns}
                    data={filteredData}
                    defaultSortField="name"
                    expandableRows
                    expandableRowsComponent={<ExpanableComponent />}
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo}
                    pagination={true}
                />

            </div>
            {showModal&&(<UserModal setShowModal={setShowModal} data={showModal} />)}
        </>
    )
}

export default Products


const ExpanableComponent = ({data}) =>(
    <div className="p-4">
        <ul className="list-group list-group-flush">
            <li className="list-group-item">
                <div className="row">
                    <div className="col">
                        <div className="row">
                            <div className="col-md-4">{__('Actual average')}:</div>
                            <div className="col-md-8">
                                <div>{__('price')}: <b>{data.actual_avg.price} €</b></div>
                                <div>{__('number')}: <b>{data.actual_avg.number}</b></div>
                                <div>{__('usage')}: <b>{data.actual_avg.pr_usage}%</b></div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="row">
                            <div className="col-md-4">{__('Future average')}:</div>
                            <div className="col-md-8">
                                <div>{__('price')}: <b>{data.future_avg.price} €</b></div>
                                <div>{__('number')}: <b>{data.future_avg.number}</b></div>
                                <div>{__('usage')}: <b>{data.future_avg.pr_usage}%</b></div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li className="list-group-item">
                <div className="row">
                    <div className="col-md-4">{__('Data sources')}:</div>
                </div>
                <div className="row">
                    <div className="col-md-8 offset-md-4">
                        <ul className="list-group list-group-horizontal">
                            <li className="list-group-item">{__('Time point')}:<br /> <b>{data.data_source.time_point}</b></li>
                            <li className="list-group-item">{__('Study name')}:<br /> <b>{data.data_source.study_name}</b></li>
                            <li className="list-group-item">{__('Total eyes')}:<br /> <b>{data.data_source.total_eyes}</b></li>
                            <li className="list-group-item">{__('YAG capsulotomy')}:<br /> <b>{data.data_source.yag_capsulotomy}</b></li>
                        </ul>
                    </div>
                </div>

            </li>
        </ul>
    </div>
)

const FilterComponent = ({ filterText, onFilter, onClear, openModal }) => (
    <div className="row w-100 text-right">
        <div className="col-md-2 offset-md-7"><button className="btn btn-primary btn-sm" onClick={openModal}>{__('New')}</button></div>
        <div className="col-md-3">
            <div className="input-group input-group-sm">
                <input type="text" className="form-control" placeholder={__("Filter By name or model")} value={filterText} onChange={onFilter}/>
                <div className="input-group-append">
                    <button type="button" className="input-group-text btn" onClick={onClear}>X</button>
                </div>
            </div>
        </div>
    </div>

);


const IconPencil = () => (
    <svg className="bi bi-pencil" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" d="M11.293 1.293a1 1 0 0 1 1.414 0l2 2a1 1 0 0 1 0 1.414l-9 9a1 1 0 0 1-.39.242l-3 1a1 1 0 0 1-1.266-1.265l1-3a1 1 0 0 1 .242-.391l9-9zM12 2l2 2-9 9-3 1 1-3 9-9z"/>
        <path fillRule="evenodd" d="M12.146 6.354l-2.5-2.5.708-.708 2.5 2.5-.707.708zM3 10v.5a.5.5 0 0 0 .5.5H4v.5a.5.5 0 0 0 .5.5H5v.5a.5.5 0 0 0 .5.5H6v-1.5a.5.5 0 0 0-.5-.5H5v-.5a.5.5 0 0 0-.5-.5H3z"/>
    </svg>
)

const IconTrash = () => (
    <svg className="bi bi-trash" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
        <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
    </svg>
)


const UserModal = ({setShowModal, data}) =>  {
    data = typeof data == "boolean" ? {actual_avg:{}, future_avg:{}, data_source: {}} : data
    const modal = useRef()
    const formData = useRef()
    const [role, setRole] = useState(data.role || 'commercial')

    const dispatch = useDispatch();

    useEffect(()=>{
        setTimeout(()=>{modal.current.classList.add('show')},100)
    },[])

    const savedProduct = e => {
        e.preventDefault()

        let dataForm = Array.from(formData.current.elements).reduce((u, formInput)=>{
            if(!formInput.name) return u
            if(formInput.name.indexOf('.')!=-1){
                let name = formInput.name.split('.')
                return {...u, ...{[name[0]]: {...u[name[0]], [name[1]]: formInput.value}}}
            } else
                return {...u, ...{[formInput.name]: formInput.value}}

        },{})
        dispatch(update_product(dataForm))
        setShowModal(false)
        return false
    }

    return (
        <>
        <div className="modal fade pt-4" ref={modal} tabIndex="-1" role="dialog" aria-hidden="true" style={{display:'block',width:'100%'}}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{__('Edit Product')}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>{setShowModal(false)}}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form ref={formData} onSubmit={savedProduct} action="#" id="form-user">
                            {data._id&&<input type="hidden" name="_id" value={data._id}/>}
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="prod_name">{__('name')}</label>
                                    <input type="text" className="form-control" name="name" id="prod_name" defaultValue={data.name} required />
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="prod_model">{__('model')}</label>
                                    <input type="text" className="form-control" name="model" id="prod_model" defaultValue={data.model} required />
                                </div>

                                <div className="form-group col-md-6">
                                    <label htmlFor="prod_brand">{__('brand')}</label>
                                    <input type="text" className="form-control" name="brand" id="prod_brand" defaultValue={data.brand} required />
                                </div>

                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <h5>{__('current average')}</h5>
                                    <div className="row">
                                        <label className="col-md-4">{__('price')}</label>
                                        <div className="col-md-8"><input type="number" step="any" className="form-control" name="actual_avg.price" defaultValue={data.actual_avg.price} required /></div>
                                        <label className="col-md-4">{__('number')}</label>
                                        <div className="col-md-8"><input type="number" className="form-control" name="actual_avg.number" defaultValue={data.actual_avg.number} required /></div>
                                        <label className="col-md-4">{__('usage %')}</label>
                                        <div className="col-md-8"><input type="number" className="form-control" name="actual_avg.pr_usage" defaultValue={data.actual_avg.pr_usage} required /></div>

                                    </div>
                                </div>
                                <div className="form-group col-md-6">
                                    <h5>{__('future average')}</h5>
                                    <div className="row">
                                        <label className="col-md-4">{__('price')}</label>
                                        <div className="col-md-8"><input type="number" step="any" className="form-control" name="future_avg.price" defaultValue={data.future_avg.price} required /></div>
                                        <label className="col-md-4">{__('number')}</label>
                                        <div className="col-md-8"><input type="number" className="form-control" name="future_avg.number" defaultValue={data.future_avg.number} required /></div>
                                        <label className="col-md-4">{__('usage %')}</label>
                                        <div className="col-md-8"><input type="number" className="form-control" name="future_avg.pr_usage" defaultValue={data.future_avg.pr_usage} required /></div>
                                    </div>
                                </div>
                            </div>
                            <h5>{__('data sources')}</h5>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <div className="row">
                                        <label className="col-md-4">{__('time point')}</label>
                                        <div className="col-md-8"><input type="number" step="any" className="form-control" name="data_source.time_point" defaultValue={data.data_source.time_point} required /></div>
                                        <label className="col-md-4">{__('study name')}</label>
                                        <div className="col-md-8"><input className="form-control" name="data_source.study_name" defaultValue={data.data_source.study_name} required /></div>
                                    </div>
                                </div>
                                <div className="form-group col-md-6">
                                    <div className="row">
                                        <label className="col-md-4">{__('total eyes')}</label>
                                        <div className="col-md-8"><input type="number" className="form-control" name="data_source.total_eyes" defaultValue={data.data_source.total_eyes} required /></div>
                                        <label className="col-md-4">{__('YAG capsulotomy')}</label>
                                        <div className="col-md-8"><input type="number" step="any" className="form-control" name="data_source.yag_capsulotomy" defaultValue={data.data_source.yag_capsulotomy} required /></div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={()=>{setShowModal(false)}}>{__('Close')}</button>
                        <button type="submit" className="btn btn-primary" form="form-user">{__('Save changes')}</button>
                    </div>
                </div>
            </div>
        </div>
        <div className="modal-backdrop fade show"></div>
        </>
    )
}
