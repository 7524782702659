import { createStore, compose, applyMiddleware } from "redux";
import createSagaMiddleware from 'redux-saga'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { composeWithDevTools } from "redux-devtools-extension";
import { offlineMiddleware } from 'redux-offline-queue'


import mySaga from './middleware'

const sagaMiddleware = createSagaMiddleware()

const persistConfig = {
    key: 'pitcher-tool',
    blacklist: ['result', 'offline'],
    storage
  }

import RootReducer from "./reducer";

const middleware = applyMiddleware(sagaMiddleware);
const enhancer = compose(middleware)

const Store = createStore(
    persistReducer(persistConfig, RootReducer), {},
    composeWithDevTools(enhancer));

sagaMiddleware.run(mySaga)

export const persistor = persistStore(Store, null, ()=>{
    return
})

export default Store
