import React, {useEffect, useState, useRef, useMemo} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {round, log, exp, evaluate, map, max} from 'mathjs'

/** charts */
import echarts from 'echarts/lib/echarts'
// include bar chart
import 'echarts/lib/chart/bar'
// include tooltip and title component
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/title'
import 'echarts/lib/component/legend'

import __ from '../lang'


const print_rep_b = (str, txt, what='{0}') => {
    let split_str = str.split(what)
    split_str.splice(1, 0, <b key="l1">{txt}</b>)
    return split_str
}

const DashboardPublicHealth = ({currentInterview, reductionOfNumOfYag, configType, calcBaseProdData, selectedYear}) => {

    const [tabShow, setTabShow] = useState('costs-scenarios')

    let tot_efficient_current_future = reductionOfNumOfYag.map((rnyag, i) => {
        let time = 60
        let p_cent = 0.01
        let consult_div_time = currentInterview.costs_resources.duration_consultion / time

        let time_perform_YAG_procedure = rnyag * currentInterview.costs_resources.duration_yag_laser / time
        let followup_visits_post_YAG = rnyag * currentInterview.costs_resources.laser_num * consult_div_time

        let vitrectomy = rnyag * p_cent * currentInterview.clinical_data.vitrectomy *
                        currentInterview.costs_resources.vitrectomy_num * consult_div_time
        let glaucoma_treatment = rnyag * p_cent * currentInterview.clinical_data.glaucoma *
                                currentInterview.costs_resources.glaucoma_num * consult_div_time
        let omc_treatment = rnyag * p_cent * currentInterview.clinical_data.omc *
                            currentInterview.costs_resources.omc_num * consult_div_time

        return [...[time_perform_YAG_procedure,followup_visits_post_YAG], ...(configType!='private'?[vitrectomy,glaucoma_treatment,omc_treatment]:[])]
    })

    let costs_scenarios

    useEffect(()=>{
        if(tabShow == 'costs-scenarios'){
            costs_scenarios = echarts.init(document.getElementById('costs-scenarios'))
            costs_scenarios.setOption(barChartOpt(
                    [...[
                        __("Time to perform a Nd:YAG procedure"),
                        __("Follow-up visits post Nd:YAG")],
                    ...(configType!='private'?[__("Vitrectomy"),
                        __("Glaucoma treatment"),
                        __("OMC treatment")
                    ]:[])],
                    tot_efficient_current_future
            ))
        }
    }, [tabShow])


    return (
        <div className="content " style={{width:'97vw'}}>
            <h3 className="mb-3 text-capitalize">{__('Efficiency')}</h3>
                <div className="row">

                    <div className="col-12 " >
                        {tabShow=='costs-scenarios'&&<h5>{__('Time spent on Nd:YAG procedures and complications associated with consultation time under the 2 scenarios')}</h5>}
                        <div className="row">
                            <div className="col-8">
                                <div id={tabShow} style={{width:'100%', height:'60vh'}}></div>
                            </div>
                            <div className="col-4 d-flex align-items-center pr-5">
                                <h4>
                                {print_rep_b(__('The total time saved by doing less YAG and having fewer complications to manage is: {0} hours'),
                                round(tot_efficient_current_future[0].reduce((p,v,i)=>(v-tot_efficient_current_future[1][i] + p),0)))}
                                </h4>
                            </div>
                        </div>
                    </div>

            </div>

        </div>
    )
}

export default DashboardPublicHealth


const default_color = ['#00aeef', '#007483','#e5d4b7','#9bbb59','#9bbb59','#ff0000','#01ad44','#713f94']


const barChartOpt = (category, data)=>({

    tooltip: {},
    legend:{

    },
    xAxis: {
        data: category,
        silent: false,
        splitLine: {
            show: false
        },
        splitArea: {
            show: false
        },
        axisLabel:{
            interval: 0,
            formatter: (v) => (v.split(' ').map((v,i) =>i%2?v+"\n":v ).join(' '))
        }
    },
    yAxis: { name: __("Hours"), nameLocation: 'middle', nameGap: 25, max: val=>round(val.max + 10) },
    series: (()=>{let o = data.map((v, i)=>(
        {
            name: i==0?__('Current scenario'):__('future scenario'),
            type: 'bar',
            itemStyle:{
                color: default_color[i]
            },
            tooltip: {

            },
            label:{
                show: true,
                rotate: 0,
                color:'#333',
                position : 'top',
                backgroundColor: '#fff8',
                padding: 5
            },
            data: v.map(val=>round(val,2))
        }
    )); o.splice(1,0,{
        type: 'scatter',
        symbolOffset : [0, '-100%'],
        symbolSize : 50,
        data: data[0].map((v,i)=> max(v, data[1][i])),
        itemStyle:{ color: '#b3c82b' },
        label: {
            show: true,
            fontSize: 14,
            color: '#333',
            formatter: (obj) =>(round(data[0][obj.dataIndex] - data[1][obj.dataIndex], 2))
        }
    });
    return o})()

})




const IconInfo = () => (
    <svg width="3em" height="3em" className="svg-inline--fa text-primary" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path>
    </svg>
)

const IconPlus = () => (
    <svg width="2.5em" height="2.5em" className="svg-inline--fa text-primary" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
        <path fill="currentColor" d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path>
    </svg>
)

const IconPrev = () => (
    <svg  width="2.2em" height="2.2em" className="svg-inline--fa fa-angle-double-left fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
        <path fill="currentColor" d="M223.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L319.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L393.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34zm-192 34l136 136c9.4 9.4 24.6 9.4 33.9 0l22.6-22.6c9.4-9.4 9.4-24.6 0-33.9L127.9 256l96.4-96.4c9.4-9.4 9.4-24.6 0-33.9L201.7 103c-9.4-9.4-24.6-9.4-33.9 0l-136 136c-9.5 9.4-9.5 24.6-.1 34z"></path>
    </svg>
)
