import React, {useEffect, useState, useRef, useMemo} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { update_inteview } from '../actions'

import __ from '../lang'

const StepConfig = ({currentInterview}) => {

    const products = useSelector(({products})=>products.list)

    /* temp data */
    //currentInterview = {...{name:'',type:'number'}, ...currentInterview}

    const [formData, setFormData] = useState({...{selectedProd:[], name:'',type:''}, ...currentInterview })
    const form = useRef()
    const dispatch = useDispatch()

    const toggleProduct = _id => {
        setFormData(
            formData.selectedProd.indexOf(_id)==-1 ? {...formData, selectedProd: [...formData.selectedProd, _id]} :
            {...formData, selectedProd: formData.selectedProd.filter(id=>id!=_id)}
        )
    }

    const next = (e) => {
        e.preventDefault();
        e.stopPropagation();

        form.current.classList.add('was-validated')
        if(formData.selectedProd.length==0) return toast.warning(__('select at least one product'))
        if(form.current.checkValidity()==false) return

        dispatch(update_inteview({...formData, step:2}))

    }

    return (
        <>
        <div className="content w-50 mb-4">
            <form className="needs-validation" noValidate id="step-conf" ref={form} onSubmit={next}>
                <div className="form-group">
                    <h5 >{__('Respondent')}:</h5>
                    <input className="form-control" placeholder={__('name respondent')+'..'} defaultValue={currentInterview.name}
                        onChange={e=>{setFormData({...formData, name: e.target.value})}} required />
                </div>
                <hr/>
                <h5>{__('Select type of data')}:</h5>
                <div  onChange={e=>{setFormData({...formData, type: e.target.value})}}>
                    <div className="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="data-type" name="data-type" className="custom-control-input" value="number" required defaultChecked={currentInterview.type=='number'} />
                        <label className="custom-control-label" htmlFor="data-type">{__('Total number of IOL')}</label>
                    </div>
                        <div className="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="data-type2" name="data-type" className="custom-control-input" value="usage" required defaultChecked={currentInterview.type=='usage'} />
                        <label className="custom-control-label" htmlFor="data-type2">{__('Market share (%)')}</label>
                    </div>
                    <div className="invalid-feedback">
                        {__('Please select type')}
                    </div>
                </div>
                <hr/>
                <div className="row">
                    <div className="col"><h5>{__('Select products')}:</h5></div>
                    <div className="col">
                        <div className="text-right">
                            <div className="btn-group">
                                <button type="button" className="btn btn-info"
                                    onClick={()=>{ setFormData({...formData, selectedProd: products.map(({_id})=>_id)}) }}>
                                        {__('all')}
                                </button>
                                <button type="button" className="btn btn-secondary"
                                    onClick={()=>{setFormData( {...formData, selectedProd: []} )}}>
                                    {__('clear')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="list-group mt-2">
                    {products&&products.map(({_id, name}) => (
                        <a href="#" key={_id}
                            onClick={()=>{toggleProduct(_id)}}
                            className={"list-group-item py-2 list-group-item-action" + (formData.selectedProd.indexOf(_id)!=-1?' active':'')}>
                                <div className="custom-control custom-radio">
                                    <input type="radio" className="custom-control-input" checked={formData.selectedProd.indexOf(_id)!=-1} readOnly />
                                    <label className="custom-control-label">{name}</label>
                                </div>
                        </a>
                    ))}
                </div>
            </form>
            <br />
        </div>

        <div className="position-fixed p-1 text-right" style={{right:0,top:'50%'}}>
            <button type="submit" className="btn btn-primary" form="step-conf">{__('next')+' >'} </button>
        </div>
        </>
    )
}

export default StepConfig
