import {PRODUCT_STORED, PRODUCT_DELETE, PRODUCT_FETCH_ERROR, PRODUCT_UPDATE} from '../actions'


const initialState = {
    list: [],
    error: ''

};

export default (state = initialState, action) => {

    switch (action.type) {
        case PRODUCT_STORED: {
            return {
                ...state,
                ...{list: action.payload},
                ...{error: ''}
            };
        }

        case PRODUCT_UPDATE: {
            return {
                ...state,
                list: [...(state.list.filter(({_id})=>(!action.payload._id||action.payload._id != _id))), action.payload]
            }
        }

        case PRODUCT_FETCH_ERROR: {
            return {...state, ...{error: action.payload}}
        }

        case PRODUCT_DELETE: {
            return {...state, ...{list: state.list.filter(({_id}) => (action.payload !== _id) )}}
        }

        default: {
            return state;
        }
    }
};
