import {USER_STORED, USER_DELETE, USER_FETCH_ERROR, USER_UPDATE} from '../actions'


const initialState = {
    list: [],
    error: ''

};

export default (state = initialState, action) => {

    switch (action.type) {
        case USER_STORED: {
            return {
                ...state,
                list: action.payload,
                error: ''
            };
        }

        case USER_UPDATE: {
            return {
                ...state,
                list: [...(state.list.filter(({id})=>(!action.payload.id||action.payload.id != id))), action.payload]
            }
        }

        case USER_FETCH_ERROR: {
            return {...state, ...{error: action.payload}}
        }

        case USER_DELETE: {
            return {...state, ...{list: state.list.filter(({id}) => (action.payload !== id) )}}
        }

        default: {
            return state;
        }
    }
};
