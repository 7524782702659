import React, {useEffect, useState, useRef, useMemo} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { update_inteview} from '../actions'
/** charts */
import echarts from 'echarts/lib/echarts'
// include bar chart
import 'echarts/lib/chart/bar'
// include tooltip and title component
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/title'
import 'echarts/lib/component/legend'

import __ from '../lang'


const StepPreviewCosts = ({currentInterview}) => {


    const step = useSelector(({interviews:{current}})=>(current.step))
    const dispatch = useDispatch()

    const products = useSelector(({products})=>products.list).filter(({_id})=>currentInterview.selectedProd.includes(_id))
    const [avgData, setAvgData] = useState(currentInterview.custom_avg ||
            {actual_cataract:4000, future_cataract:4000, list:products.map(({_id,actual_avg, future_avg})=>({_id,actual_avg, future_avg}))})
    const unitShow = currentInterview.type=='number' ? 'number' : 'pr_usage'

    let charts

    useEffect(()=>{
        charts = echarts.init(document.getElementById('current_future'))
        charts.setOption(barChartOpt(
                    products.map(({name})=>name),
                    avgData.list.map(({actual_avg})=>actual_avg[unitShow]*(unitShow=='number'?1:(avgData.actual_cataract/100))),
                    avgData.list.map(({future_avg})=>future_avg[unitShow]*(unitShow=='number'?1:(avgData.future_cataract/100)))
        ))
        return(() =>{
            dispatch(update_inteview({ custom_avg: avgData}))})
    }, [avgData, step])


    const changeStep = step => {
        dispatch(update_inteview({...currentInterview, custom_avg: avgData, step: currentInterview.step + step}))
    }

    const AlertVal = avgData.list.reduce((p, pp)=>(p + parseFloat(pp[['actual_avg']][unitShow]) ), 0) !=
    avgData.list.reduce((p, pp)=>(p + parseFloat(pp[['future_avg']][unitShow]) ), 0)&&
    (<div className="alert alert-danger" role="alert">
    {__('The total in the current and future scenarios must be equal!')}
  </div>)

    return (
        <>
        <div className="content w-100 p-4">
            <h4>{__('Number of eyes treated and expected projection')}</h4>
            <div className="row mt-2">
                <div className="col-sm-5 overflow-auto" style={{maxHeight:'75vh'}}>
                    <small>
                        {__('Enter the number of eyes and the price of iols : currently and in the future')}
                    </small>
                    {['actual_avg', 'future_avg'].map(tt=>(

                        <table className="table table-sm" key={tt}>
                        <thead>
                            <tr>
                                <th colSpan={3}>{tt=='actual_avg'?__('Current scenario'):__('Future scenario')}</th>
                            </tr>{unitShow=='number'?null:(
                                    <tr><td colSpan={2}><small>{__('Number of cataract operations')}:</small></td><td><small>
                                        <input type="number" className="form-control" defaultValue={avgData[tt=='actual_avg'?'actual_cataract':'future_cataract']}
                                    onChange={e=>{setAvgData({...avgData, [tt=='actual_avg'?'actual_cataract':'future_cataract']: e.target.value})}} /></small></td></tr>
                                )}<tr>
                                <th></th>
                                <th scope="col">{__('Average estimated price')}</th>
                                <th scope="col">{unitShow=='number'?__('Nb of eyes') : __('Usage')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {products.map( p => {
                                let avg_p = avgData.list.find(({_id})=>_id==p._id)
                                if(avg_p)
                                return (
                                <tr key={avg_p._id}>
                                    <td className="text-nowrap">{p.name}</td>
                                    <td>
                                        <div className="input-group input-group-sm">
                                            <input type="text" className="form-control" defaultValue={avg_p[tt].price}
                                                onChange={e=>{setAvgData({...avgData,list: avgData.list.map(p => (p._id!=avg_p._id?p:{...p, [tt]:{...p[tt],price: e.target.value}})) })}}  />
                                            <div className="input-group-append">
                                                <span className="input-group-text">€</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="input-group input-group-sm">
                                            <input type="number" step={unitShow=='number'?1:"any"} className="form-control form-control-sm" defaultValue={avg_p[tt][unitShow]}
                                                onChange={e=>{setAvgData({...avgData, list: avgData.list.map(p => (p._id!=avg_p._id?p:{...p, [tt]:{...p[tt],[unitShow]: e.target.value}})) })}} />
                                            <div className="input-group-append">
                                                <span className="input-group-text">{unitShow=='number'?'':"%"}</span>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            )})}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan={2}>{('Total')}:</td>
                                <td><b>{avgData.list.reduce((p, pp)=>(p + parseFloat(pp[[tt]][unitShow]) ), 0)} {unitShow=='number'?'':'%'}</b></td>
                            </tr>
                        </tfoot>
                        <tfoot>
                            <tr><td colSpan={3}>
                                {AlertVal}
                            </td></tr>
                        </tfoot>
                        </table>
                    ))}

                </div>
                <div className="col-sm-7 d-flex flex-wrap justify-content-center">
                    <h5>{__('Split of IOLs')}</h5>
                    <div id="current_future" style={{width:'100%', height:'60vh'}}></div>
                </div>
            </div>
        </div>

        <div className="position-fixed p-1 text-right" style={{right:0,top:'50%'}}>
            <button type="submit" className="btn btn-primary" onClick={()=>{changeStep(1)}}>{__('next')+' >'} </button>
        </div>

    </>
    )
}

export default StepPreviewCosts


const barChartOpt = (category, actual, future)=>({

    tooltip: {},
    legend:{
        selectMode: false
    },
    xAxis: {
        data: category,
        axisLabel: {
            rotate:-30,
            margin: 8,
            fontSize: 10,
        }
    },
    yAxis: {},
    series: [{
        name: __('Current'),
        type: 'bar',
        itemStyle:{
            color: '#00aeef'
        },
        label:{
            show: true,
            rotate: 90,
            fontSize:12,
            color:'#FFF'
        },
        data: actual
    }, {
        name: __('Future'),
        type: 'bar',
        itemStyle:{
            color: '#007483'
        },
        label:{
            show: true,
            rotate: 90,
            fontSize:12,
            color:'#FFF'
        },
        data: future
    }]
})
