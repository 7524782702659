import React, {useEffect, useState, useRef, useMemo} from 'react';
import {round, log, exp, evaluate, map} from 'mathjs'
import {format_num} from './global_tool'

/** charts */
import echarts from 'echarts/lib/echarts'
// include bar chart
import 'echarts/lib/chart/bar'
// include tooltip and title component
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/title'
import 'echarts/lib/component/legend'

import __ from '../lang'



const DashboardPublicHealth = ({currentInterview, reductionOfNumOfYag, calcBaseProdData, selectedYear}) => {

    const [tabShow, setTabShow] = useState('costs-scenarios')


    let tot_compl_YAG_current_future = useMemo(()=>( reductionOfNumOfYag.map((rnyag, i) => {
        let const_complic_vetrectomy = rnyag * currentInterview.clinical_data.vitrectomy //(E22)*(pr_complications)
        let const_complic_glaucoma = rnyag * currentInterview.clinical_data.glaucoma //(E22)*(pr_complications)
        let const_complic_omc = rnyag * currentInterview.clinical_data.omc //(E22)*(pr_complications)

        let total_cost_vitrectomy = round(const_complic_vetrectomy * currentInterview.costs_resources.vitrectomy / 100,2); //(c_complication)
        let total_cost_glaucoma = round(const_complic_glaucoma * currentInterview.costs_resources.glaucoma / 100,2);
        let total_cost_omc = round(const_complic_omc * currentInterview.costs_resources.omc / 100,2);

        let total_cost_yag = currentInterview.custom_avg.list.reduce((p,
                                    {_id, actual_avg:{number: num_act,pr_usage:pru_act}, future_avg:{number: num_fut,pr_usage:pru_fut}})=>{
                let a,f
                if(currentInterview.type=='number'){
                    a = num_act
                    f = num_fut
                }else{
                    a = parseFloat(pru_act)?(parseInt(currentInterview.custom_avg.actual_cataract) * (parseFloat(pru_act) / 100)):0
                    f = parseFloat(pru_fut)?(parseInt(currentInterview.custom_avg.actual_cataract) * (parseFloat(pru_fut) / 100)):0
                }

                let ndYag = calcBaseProdData.find((p)=>p._id==_id).calc[`prob_YAG_caps_y${selectedYear}`]
                return (
                    [(a * ndYag * currentInterview.costs_resources.laser) + p[0],
                    (f * ndYag * currentInterview.costs_resources.laser) + p[1]]
            )},[0, 0])


        let total_cost_visit = (rnyag * currentInterview.costs_resources.laser_num  +
                            const_complic_vetrectomy * 0.01 * currentInterview.costs_resources.vitrectomy_num  +
                            const_complic_glaucoma * 0.01  * currentInterview.costs_resources.glaucoma_num  +
                            const_complic_omc * 0.01 * currentInterview.costs_resources.omc_num) *
                            currentInterview.costs_resources.cost_consult

        return [total_cost_yag[i], total_cost_visit, total_cost_vitrectomy, total_cost_glaucoma, total_cost_omc]
    })),[reductionOfNumOfYag])

    let costs_scenarios, costs_scenarios2

    useEffect(()=>{
        if(tabShow == 'costs-scenarios'){
            costs_scenarios = echarts.init(document.getElementById('costs-scenarios'))
            costs_scenarios.setOption(barChartOpt(
                    [
                        __("Total cost of procedures Nd:YAG"),
                        __("Total cost of consultation visits"),
                        __("Total cost of vitrectomy"),
                        __("Total cost of glaucoma treatment"),
                        __("Total cost of processing OMC")
                    ],
                    tot_compl_YAG_current_future
            ))
        }

        if(tabShow == 'costs-scenarios2'){
            costs_scenarios2 = echarts.init(document.getElementById('costs-scenarios2'))
            costs_scenarios2.setOption(barChartOpt_scenarios2(
                    [
                        __("Total cost of procedures Nd:YAG"),
                        __("Total cost of consultation visits"),
                        __("Total cost of vitrectomy"),
                        __("Total cost of glaucoma treatment"),
                        __("Total cost of processing OMC"),
                        __('Cost of complications (CME, vitrectomy, glaucoma)'),
                        __('Total earnings')
                    ],
                    [...tot_compl_YAG_current_future[0].map((v,i) => (v-tot_compl_YAG_current_future[1][i])),
                    tot_compl_YAG_current_future[0].slice(-3).reduce((p,v)=>(p+v)) - tot_compl_YAG_current_future[1].slice(-3).reduce((p,v)=>(p+v)),
                    tot_compl_YAG_current_future[0].reduce((p,v)=>(p+v)) - tot_compl_YAG_current_future[1].reduce((p,v)=>(p+v))
                    ]
            ))
        }

    }, [tabShow])


    return (
        <div className="content " style={{width:'97vw'}}>
            <h1 className="mb-3 text-capitalize">{__('Public Health')}</h1>
                <div className="row">

                    <div className="col-12 " >
                        {tabShow=='costs-scenarios'&&<h5>{__('Breakdown of costs under the 2 scenarios')}</h5>}
                        {tabShow=='costs-scenarios2'&&<h5>{__('Impact du changement de scénarios')}</h5>}
                        {tabShow=='costs-scenarios2'&&<h4 className="mb-4">
                                {__('The overall savings for health insurance related to the reduction in the number of Yag are')}
                                : <b className="text-nowrap">{format_num(round(
                                    tot_compl_YAG_current_future[0].reduce((p,v)=>(p+v)) - tot_compl_YAG_current_future[1].reduce((p,v)=>(p+v)),2))}€</b>
                                </h4>}
                        <div className="row">
                            <div className="col-8">
                                {tabShow=='costs-scenarios'&&(<div id={tabShow} style={{width:'100%', height:'60vh'}}></div>)}
                                {tabShow=='costs-scenarios2'&&(<div id={tabShow} style={{width:'100%', height:'60vh'}}></div>)}
                            </div>
                            <div className="col-4 d-flex align-items-center flex-column">
                                <button className="btn" data-toggle="collapse" style={{marginTop:'-2rem'}} data-target="#p1_info"><IconInfo /></button>
                                <div className="collapse" id="p1_info">
                                    <div className="card card-body mt-4">
                                        <table className="table table-sm">
                                            <thead><tr><th width="70%"></th><th>{__('Cost / procedure')}</th></tr></thead>
                                            <tbody>
                                            {[
                                                ['laser', 'Laser Nd.YAG'],
                                                ['vitrectomy','Vitrectomy'],
                                                ['glaucoma', 'Treatment of glaucoma'],
                                                ['omc', 'OMC treatment'],
                                                ['cost_consult', 'Cost of a consultation']].map(ll =>(
                                                <tr key={ll[0]}>
                                                    <td>{__(ll[1])}</td>
                                                    <td className="border border-primary">
                                                        {currentInterview.costs_resources[ll[0]]} €
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>

            {tabShow!='costs-scenarios'&&
                (<button
                    className="position-fixed btn btn-link"
                    onClick={()=>{setTabShow('costs-scenarios')}}
                    style={{top:'10.2%',right:'4%'}}><IconPrev /></button>)}

            {tabShow!='costs-scenarios2'&&
                (<button
                    className="position-fixed btn btn-link"
                    onClick={()=>{setTabShow('costs-scenarios2')}}
                    style={{top:'70%',right:'2%'}}><IconArrow /></button>)}



        </div>
    )
}

export default DashboardPublicHealth


const default_color = ['#00aeef', '#007483','#e5d4b7','#9bbb59','#9bbb59','#ff0000','#01ad44','#713f94']

const barChartOpt_scenarios2 = (category, data)=>({

    tooltip: {},
    legend:{
        icon: 'none'
    },
    xAxis: {
        data: category,
        silent: false,
        splitLine: {
            show: false
        },
        splitArea: {
            show: false
        },
        axisLabel:{
            interval: 0,
            formatter: (v) => (v.split(' ').map((v,i) =>i%2?v+"\n":v ).join(' '))
        }
    },
    yAxis: {},
    series: [
        {
            name: __('Cost (-)/ Savings (+)'),
            type: 'bar',
            itemStyle:{
                color: ({dataIndex})=> ((dataIndex==6)? [default_color[3]] : [default_color[0]])
            },
            tooltip: {
                formatter: '{a}<br />{b0}: {c0}€'
            },
            label:{
                show: true,
                rotate: 0,
                color:'#333',
                position : 'top',
                formatter:({value}) => (format_num(value)+'€'),
                backgroundColor: '#fff8',
                padding: 5
            },
            data: data.map(v=>round(v))
        }
    ]
})

const barChartOpt = (category, data)=>({

    tooltip: {},
    legend:{

    },
    xAxis: {
        data: category,
        silent: false,
        splitLine: {
            show: false
        },
        splitArea: {
            show: false
        },
        axisLabel:{
            interval: 0,
            formatter: (v) => (v.split(' ').map((v,i) =>i%2?v+"\n":v ).join(' '))
        }
    },
    yAxis: {},
    series: data.map((v, i)=>(
        {
            name: i==0?__('Current scenario'):__('future scenario'),
            type: 'bar',
            itemStyle:{
                color: default_color[i]
            },
            tooltip: {
                formatter: '{a}<br />{b0}: {c0}€'
            },
            label:{
                show: true,
                rotate: 0,
                color:'#333',
                position : 'top',
                formatter:({value}) => (format_num(value)+'€'),
                backgroundColor: '#fff8',
                padding: 5
            },
            data: v.map(val=>round(val))
        }
    ))

})


const IconInfo = () => (
    <svg width="3em" height="3em" className="svg-inline--fa text-primary" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path>
    </svg>
)

const IconPlus = () => (
    <svg width="2.5em" height="2.5em" className="svg-inline--fa text-primary" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
        <path fill="currentColor" d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path>
    </svg>
)

const IconPrev = () => (
    <svg  width="2.2em" height="2.2em" className="svg-inline--fa fa-angle-double-left fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
        <path fill="currentColor" d="M223.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L319.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L393.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34zm-192 34l136 136c9.4 9.4 24.6 9.4 33.9 0l22.6-22.6c9.4-9.4 9.4-24.6 0-33.9L127.9 256l96.4-96.4c9.4-9.4 9.4-24.6 0-33.9L201.7 103c-9.4-9.4-24.6-9.4-33.9 0l-136 136c-9.5 9.4-9.5 24.6-.1 34z"></path>
    </svg>
)

const IconArrow = () => (
    <img src="/assets/images/next.png" style={{width:'5em'}} />

)
