import React, {useEffect, useState, useRef, useMemo} from 'react';
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment';
import { toast } from 'react-toastify';
import {ErrorBoundary} from 'react-error-boundary'

import {fetch_products, fetch_configurations, update_inteview,fetch_history_study_data,
        POOLING_SAVE,CHANGE_TYPE_CONFIG, NEW_INTERVIEW,FORCE_RESET} from '../actions'

import StepConfig from './step_config'
import StepPreviewCosts from './step_preview_costs'
import StepClinicalData from './step_clinical_data'
import StepCost from './step_cost'
import DashboardResult from './dashboard_result'

import __ from '../lang'


const Interview = ({}) => {

    const currentInterview = useSelector(({interviews})=>interviews.current)
    const queueInterview = useSelector(({interviews})=>interviews.queue)
    const config_type = useSelector(state=>state.configurations.type)
    const user = useSelector(({auth})=>auth.profile)
    const dispatch = useDispatch()

    if(!Array.isArray(queueInterview))
        dispatch({type:FORCE_RESET});

    useEffect(()=>{
        dispatch(fetch_products())
        dispatch(fetch_configurations())
        dispatch(fetch_history_study_data())
    },[])

    useEffect(()=>{
        const urlParams = new URLSearchParams(window.location.search);
        const isPrivate = urlParams.get('private')?'private':'public';
        if(config_type!=isPrivate)
            dispatch({type: CHANGE_TYPE_CONFIG, payload: isPrivate=='private' })
    }, [config_type])

    useEffect(()=>{
        let timeout
        if((queueInterview||[]).length > 0)
            timeout = setInterval(()=>{ dispatch({type: POOLING_SAVE}); }, 30000)
        return ()=>{ clearInterval(timeout) }
    }, [(queueInterview || []).length])

    let content
    switch (currentInterview.step) {
        case 1:
            content = <StepConfig currentInterview={currentInterview} />
            break;
        case 2:
            content = <StepPreviewCosts currentInterview={currentInterview} />
            break;
        case 3:
            content = <StepClinicalData currentInterview={currentInterview} configType={config_type} />
            break;
        case 4:
            content = <StepCost currentInterview={currentInterview} configType={config_type} />
            break;
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
        case 10:
            if(currentInterview.costs_resources){
                    content = (<ErrorBoundary  FallbackComponent={e=>(fallBackErrorNoundary(e))}>
                            <DashboardResult currentInterview={currentInterview}  configType={config_type} />
                        </ErrorBoundary>)

            } else {
                dispatch(update_inteview({...currentInterview, step: 4}))
            }
            break;
        default:
            break;
    }

    if(!currentInterview.selectedProd&&currentInterview.step>1){
        toast.warning(__('you must select at least one product'))
        dispatch(update_inteview({...currentInterview, step: 1}))
        return <div />
    }

    const fallBackErrorNoundary = e => {
            console.error('Error in ', e);
            dispatch({type:NEW_INTERVIEW});
            return (<div><button onClick={()=>{ location.reload(); }}>Reset</button></div>)
    }

    return (
        <>
        <NavBar user={user} configType={config_type} step={currentInterview.step} />
        <nav className="fixed-bottom ">

            {currentInterview.step < 5 &&<ul className="pagination justify-content-center mb-2 rounded-0">
                {['Configuration','Split of IOLs','YAG data','Procedure costs'].map((nn, i)=>(
                    <li className={"page-item col px-0"+((i+1)==currentInterview.step?' active':'')} key={i}>
                        <a className={"page-link rounded-0 text-center "+((i+1)==currentInterview.step?" bg-secondary":'bg-alcon-secondary2')}
                            onClick={()=>{dispatch(update_inteview({...currentInterview, step: (i+1)}))}} href="#">{__(nn)}</a>
                    </li>
                ))}
            </ul>}
            {currentInterview.step > 4 && currentInterview.step < 10 &&(
                <ul className="pagination justify-content-center mb-2 rounded-0">
                    {[...['Medical','Public Health','Efficiency'],
                        ...(config_type=='private' ? ['Financial (clinical)', 'Financial (surgery)'] : ['Financial'])].map((nn, i)=>(
                        <li className={"page-item col px-0"+((i+4+1)==currentInterview.step?' active':'')} key={i}>
                            <a className={"page-link text-capitalize rounded-0 text-center "+((i+5)==currentInterview.step?" bg-secondary":'bg-alcon-secondary2')}
                                onClick={()=>{dispatch(update_inteview({...currentInterview, step: (i+5)}))}} href="#">{__(nn)}</a>
                        </li>
                    ))}
                </ul> )}

                <ul className="pagination justify-content-center mb-0 rounded-0">
                    {[
                        {label:'LES INFORMATIONS À RENTRER',n:[0,5]},
                        {label:'LES IMPACTS MÉDICO-ÉCONOMIQUES', n:[5,10]},
                        {label:'SYNTHÈSE DE LA SIMULATION', n:[10,11]}].map(({label,n}, i)=>(
                        <li key={i} className={"page-item col-4 px-0 "+(currentInterview.step >= n[0] && currentInterview.step < n[1]?'active':'')}>
                            <a href="#" className="page-link rounded-0 text-center py-2"
                                onClick={()=>{dispatch(update_inteview({...currentInterview, step: Math.max(1,i * 5)}))}}>
                                    {label}
                                </a>
                        </li>
                    ))}
                </ul>

        </nav>
        <div className="container-fluid py-4 d-flex align-items-center justify-content-center bg-light" style={{minHeight:'100vh'}}>
            {content}
        </div>
        </>
    )
}

export default Interview


const NavBar = ({currentPage, setCurrentPage, configType, step}) => {

    const dispatch = useDispatch();
    const user = useSelector(({auth})=>auth.profile)
    const [date, setDate] = React.useState(moment());

    useEffect(() => {
        var timerID = setInterval( () => tick(), 30000 );
        return function cleanup() {
            clearInterval(timerID);
        };
    });



    function tick() {
        setDate(moment());
    }

    return (
        <div className="row fixed-top align-items-stretch">
            <div className="col-7 pr-0 bg-alcon-secondary">
                <nav className="navbar pr-0 py-0" style={{zIndex:2000}}>
                    <a className="navbar-brand" href="#">
                        <img src={window.media+'/Alcon_RGB.png'} height="22" alt="" loading="lazy" />
                    </a>

                    <button className="navbar-toggler " type="button" data-toggle="collapse" data-target="#navbarSupportedContent">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav mr-auto">
                            <li className={(currentPage==''?'active ':'')+"nav-item"}>
                                <a className="nav-link" href="#" onClick={()=>{setCurrentPage('')}}>{__('new')}</a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
            <div className="col pl-0 title-nav">
                <nav className="navbar py-1" style={{zIndex:2000}}>
                    <h6 className="text-secondary mt-2 text-nowrap">
                        CALCULATEUR PCO [ {__(configType)} ]

                    </h6>
                </nav>
            </div>
        </div>
    )
}
