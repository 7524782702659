import React, {useEffect, useState, useRef} from 'react';
import { useSelector, useDispatch } from "react-redux";

import {AUTH_LOGIN} from '../actions'

const __ = txt => (txt)


  const Login = () => {

    const [showPwd, setShowPwd] = useState(false)
    const name = useRef('client')
    const password = useRef('gdspw')
    const dispatch = useDispatch();

    const auth = useSelector(state => state.auth);

    const handle_submit = e => {
        e.preventDefault()
        dispatch({type: AUTH_LOGIN, payload: {name: name.current.value, password: password.current.value}})
        return false
    }

    /** autologin */
    useEffect(()=>{
        const urlParams = new URLSearchParams(window.location.search);
        const isAdmin = urlParams.get('admin');
        if(!isAdmin && name.current.value && password.current.value){
            dispatch({type: AUTH_LOGIN, payload: {name: name.current.value, password: password.current.value}})
        }
    },[])


    return (
        <div id="login" className="container">
            <h3 className="text-center text-white pt-5"><img src={window.media+'/Alcon_R.png'} height="26" alt="" loading="lazy" /></h3>
            <div className="row justify-content-center align-items-center">
                <div className="col-md-6">
                    <div className="col-md-12">
                        <form className="form" action="#" onSubmit={handle_submit}>
                            <h3 className="text-center text-info">CALCULATEUR PCO</h3>
                            <div className="form-group">
                                <label htmlFor="username" className="text-info">{__('Username')}:</label><br />
                                <input type="text" ref={name} className="form-control mb-1" required autoFocus defaultValue="client" onFocus={e => e.currentTarget.select()} />
                                {auth.error.name&&(<div className="alert alert-warning" role="alert">{auth.error.name.join(', ')}</div>)}
                            </div>
                            <div className="form-group">
                                <label htmlFor="password" className="text-info">{__('Password')}:</label><br />
                                <div className="input-group mb-1">
                                    <input type={showPwd?"text":"password"} ref={password} className="form-control" required minLength={5} defaultValue="gdspw" />
                                    <div className="input-group-append">
                                        <button className="btn  bg-white" type="button"
                                                onClick={()=>{setShowPwd(!showPwd)}}>
                                            {showPwd ? <IconEyeSlash /> : <IconEye />}
                                        </button>
                                    </div>
                                </div>
                                {auth.error.password&&(<div className="alert alert-warning" role="alert">{auth.error.password.join(', ')}</div>)}
                            </div>
                            <br />
                            {auth.error.error&&(<div className="alert alert-danger" role="alert">{__(auth.error.error)}</div>)}
                            {auth.success&&(<div className="alert alert-success" role="alert">{__(auth.success)}</div>)}
                            <button className="mt-4 btn btn-info btn-md btn-block" type="submit">
                                {__('enter')}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    )
  }


  export default Login;



const IconEye = () => (
    <svg className="bi bi-eye-fill" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
        <path fillRule="evenodd" d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
    </svg>
)

const IconEyeSlash = () => (
    <svg className="bi bi-eye-slash-fill" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.79 12.912l-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z"/>
        <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708l-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829z"/>
        <path fillRule="evenodd" d="M13.646 14.354l-12-12 .708-.708 12 12-.708.708z"/>
    </svg>
)
