import React, {useEffect, useState, useRef} from 'react';
import { useSelector, useDispatch } from "react-redux";

import {logout} from '../actions'

import Users from './users'
import Configurations from './configurations'
import Products from './products'


const __ = txt => (txt)


const Dashboard = ( ) => {

    const [currentPage, setCurrentPage] = useState('')

    let content;

    switch (currentPage) {
        case 'users':
            content = <Users />
            break;
        case 'constants':
            content = <Configurations />
            break;
        case 'products':
            content = <Products />
            break;
        default:
            content = <Home  setCurrentPage={setCurrentPage} />
    }


    return (
        <>
            <NavBar currentPage={currentPage} setCurrentPage={setCurrentPage} />

            <div className="container-md">
                <div className="content">
                    {content}
                </div>
            </div>
        </>
    )
}




const NavBar = ({currentPage, setCurrentPage}) => {

    const dispatch = useDispatch();
    const user = useSelector(({auth})=>auth.profile)

    return (
        <nav className="navbar navbar-expand-lg bg-primary navbar-dark mb-4" style={{zIndex:1031}}>
            <a className="navbar-brand" href="#">Alcon Pitcher</a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav mr-auto">
                    <li className={(currentPage==''?'active ':'')+"nav-item"}>
                        <a className="nav-link" href="#" onClick={()=>{setCurrentPage('')}}>Dashboards</a>
                    </li>
                    {['products','constants','users'].map(item=>(
                        <li className={(currentPage==item?'active ':'')+"nav-item"} key={item}>
                            <a className="nav-link text-capitalize" href="#" onClick={(e)=>{setCurrentPage(item); e.preventDefault();}}>{__(item)}</a>
                        </li>
                    ))}
                </ul>

                <ul className="navbar-nav">
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownLang" data-toggle="dropdown">
                        <IconFlag /> En
                        </a>
                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownLang">
                        <a className="dropdown-item" href="#">English</a>
                            <a className="dropdown-item" href="#">Franchise</a>
                        </div>
                    </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown">
                        <IconUser />
                        </a>
                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                            <small className="ml-4">{__('User')}: {user.name} [{user.role}]</small>
                            <a className="dropdown-item" href="#" onClick={()=>{dispatch(logout());}}>logout</a>
                        </div>
                    </li>
                </ul>

            </div>
        </nav>
    )
}

const Home = ({setCurrentPage}) => (
    <div className="d-flex justify-content-center align-items-center">
        {['products','constants','users'].map(item=>(
            <div className="card m-4" key={item}>
            <div className="card-body">
                <a href="#" onClick={()=>{setCurrentPage(item);}} >{__('go to')} {__(item)}</a>
            </div>
        </div>
        ))}

    </div>
)

export default Dashboard



const IconUser = () => (
    <svg className="bi bi-person-fill" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
     <path fillRule="evenodd" d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
    </svg>
)

const IconFlag = () => (
    <svg className="bi bi-flag" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" d="M3.5 1a.5.5 0 0 1 .5.5v13a.5.5 0 0 1-1 0v-13a.5.5 0 0 1 .5-.5z"/>
        <path fillRule="evenodd" d="M3.762 2.558C4.735 1.909 5.348 1.5 6.5 1.5c.653 0 1.139.325 1.495.562l.032.022c.391.26.646.416.973.416.168 0 .356-.042.587-.126a8.89 8.89 0 0 0 .593-.25c.058-.027.117-.053.18-.08.57-.255 1.278-.544 2.14-.544a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-.5.5c-.638 0-1.18.21-1.734.457l-.159.07c-.22.1-.453.205-.678.287A2.719 2.719 0 0 1 9 9.5c-.653 0-1.139-.325-1.495-.562l-.032-.022c-.391-.26-.646-.416-.973-.416-.833 0-1.218.246-2.223.916a.5.5 0 1 1-.515-.858C4.735 7.909 5.348 7.5 6.5 7.5c.653 0 1.139.325 1.495.562l.032.022c.391.26.646.416.973.416.168 0 .356-.042.587-.126.187-.068.376-.153.593-.25.058-.027.117-.053.18-.08.456-.204 1-.43 1.64-.512V2.543c-.433.074-.83.234-1.234.414l-.159.07c-.22.1-.453.205-.678.287A2.719 2.719 0 0 1 9 3.5c-.653 0-1.139-.325-1.495-.562l-.032-.022c-.391-.26-.646-.416-.973-.416-.833 0-1.218.246-2.223.916a.5.5 0 0 1-.554-.832l.04-.026z"/>
    </svg>
)
