import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react'
import Store, {persistor} from "./store";
import App from "./app";
import {register}  from './serviceWorker';

export function AppRoot() {

    console.log('Alcon fr pitcher App Ver.', window.AppVersion);

    return (
    <Provider store={Store}>
        <PersistGate loading={null} persistor={persistor}>
            <App />
        </PersistGate>
    </Provider>
    );
}

const rootElement = document.getElementById("app");
ReactDOM.render(<AppRoot />, rootElement);

// serviceWorker.register();
register()
