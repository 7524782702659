import {INTERVIEW_UPDATE, FORCE_RESET, SAVE_DATA, NEW_INTERVIEW, SAVE_QUEUE, EMPTY_QUEUE} from '../actions'


const initialState = {
    history: [],
    queue: [],
    current: {step:1, interest: []  },
    error: '',

};

export default (state = initialState, action) => {

    switch (action.type) {
        case INTERVIEW_UPDATE: {
            return {
                ...state,
                current: {...state.current, ...action.payload},
                error: ''
            };
        }

        case NEW_INTERVIEW: {
            return {
                ...state,
                current: initialState.current
            }
        }

        case FORCE_RESET: {
            return initialState;
        }

        case SAVE_DATA: {
            return {
                ...state,
                history: [...state.history, state.current]
            }
        }

        case SAVE_QUEUE: {
            return {
                ...state,
                queue: [...state.queue, action.payload]
            }
        }

        case EMPTY_QUEUE: {
            return {
                ...state,
                queue: []
            }
        }


        default: {
            return state;
        }
    }
};
