import {HISTORYSTUDY_FETCH_ERROR, HISTORYSTUDY_STORED, HISTORYSTUDY_UPDATE} from '../actions'


const initialState = {
    list: [],
    error: ''

};

export default (state = initialState, action) => {

    switch (action.type) {
        case HISTORYSTUDY_STORED: {
            return {
                ...state,
                ...{list: action.payload},
                ...{error: ''}
            };
        }

        case HISTORYSTUDY_UPDATE: {
            return {...state, list: state.list.map(hstudy => (
                hstudy._id==action.payload._id ? {...hstudy, value: action.payload.value} : hstudy
            ))}
        }

        case HISTORYSTUDY_FETCH_ERROR: {
            return {...state, ...{error: action.payload}}
        }

        default: {
            return state ;
        }
    }
};
