import React, {useEffect, useState, useRef, useMemo} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {round, log, exp, evaluate, map, max} from 'mathjs'
import { update_inteview } from '../actions'

/** charts */
import echarts from 'echarts/lib/echarts'
// include bar chart
import 'echarts/lib/chart/bar'
import 'echarts/lib/chart/line'
import 'echarts/lib/chart/scatter'
// include tooltip and title component
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/title'
import 'echarts/lib/component/legend'

import __ from '../lang'



const DashboardGraph = ({currentInterview, reductionOfNumOfYag, calcBaseProdData, selectedYear}) => {


    const [tabShow, setTabShow] = useState('reduction_number_yag')

    let tot_compl_YAG_current_future, reduction_number_yag, dist_num_yag_by_type, dist_num_yag_by_type2

    useEffect(()=>{

        if(tabShow == 'tot_compl_YAG_current_future'){
            console.log(
                `(${round(Object.values(currentInterview.clinical_data).reduce((p, v)=>p + v * reductionOfNumOfYag[0], 0),2)} -
                ${round(Object.values(currentInterview.clinical_data).reduce((p,v)=>p + v * reductionOfNumOfYag[1], 0),2)}) /
                ${round(Object.values(currentInterview.clinical_data).reduce((p, v)=>p + v * reductionOfNumOfYag[0], 0),2)}`
                ,
                round(
                ((Object.values(currentInterview.clinical_data).reduce((p, v)=>p + v * reductionOfNumOfYag[0], 0) -
                Object.values(currentInterview.clinical_data).reduce((p,v)=>p + v * reductionOfNumOfYag[1], 0))) /
                Object.values(currentInterview.clinical_data).reduce((p, v)=>p + v * reductionOfNumOfYag[0], 0) *100 ,1)+'%')

            let map_a = Object.values(currentInterview.clinical_data).map(v=>round(v*reductionOfNumOfYag[0]/100,1))
            let map_b = Object.values(currentInterview.clinical_data).map(v=>round(v*reductionOfNumOfYag[1]/100,1))
            tot_compl_YAG_current_future = echarts.init(document.getElementById('tot_compl_YAG_current_future'))
            tot_compl_YAG_current_future.setOption(barChartOpt_c_f_m(
                        [__('vitrectomy'), __('Treatment of glaucoma'), __('Treatment of OMC')],
                        map_a,map_a.map((o,i)=>([o,map_b[i]])), map_b))
        }


        if(tabShow == 'dist_num_yag_by_type'){

            dist_num_yag_by_type = echarts.init(document.getElementById('dist_num_yag_by_type'))
            dist_num_yag_by_type.setOption(barChartOpt(
                [__('Current scenario'), __('future scenario')],
                calcBaseProdData.map(({_id:id, name})=>{
                    let custom_avg = currentInterview.custom_avg.list.find(({_id})=>_id==id)
                    let ndyag = calcBaseProdData.find(({_id})=>_id==id).calc[`prob_YAG_caps_y${selectedYear}`]
                    return ({name, value:[
                           round( ndyag * (currentInterview.type=='number'?
                                    custom_avg.actual_avg.number :
                                    ((custom_avg.actual_avg.pr_usage || 0) * currentInterview.custom_avg.actual_cataract/100))),

                            round( ndyag * (currentInterview.type=='number'?
                                    custom_avg.future_avg.number :
                                    ((custom_avg.future_avg.pr_usage || 0) * currentInterview.custom_avg.actual_cataract/100)))
                    ]})
                }), __('Number of Nd:YAG laser')
            ))

            dist_num_yag_by_type2 = echarts.init(document.getElementById('dist_num_yag_by_type2'))
            dist_num_yag_by_type2.setOption(barChartOpt(
                [__('Current scenario'), __('future scenario')],
                calcBaseProdData.map(({_id:id, name})=>{
                    let custom_avg = currentInterview.custom_avg.list.find(({_id})=>_id==id)
                    return ({name, value:[
                        currentInterview.type=='number'?
                                custom_avg.actual_avg.number :
                                ((custom_avg.actual_avg.pr_usage || 0) * currentInterview.custom_avg.actual_cataract/100),

                       currentInterview.type=='number'?
                                custom_avg.future_avg.number :
                                ((custom_avg.future_avg.pr_usage || 0) * currentInterview.custom_avg.actual_cataract/100)
                    ]})
                }), __('total number of iol'), false
            ))

        }

        if(tabShow == 'reduction_number_yag'){
            reduction_number_yag = echarts.init(document.getElementById('reduction_number_yag'))
            reduction_number_yag.setOption(barChartOpt_current_future(
                [__('Number of Nd:YAG laser')],
                [round(reductionOfNumOfYag[0])], [round(reductionOfNumOfYag[1])]
            ))
        }

    }, [tabShow])

    const print_rep_b = (str, txt, what='{0}') => {
        let split_str = str.split(what)
        split_str.splice(1, 0, <b key="l1" style={{fontSize:'1.5em'}}>{txt}</b>)
        return split_str
    }

    return (
        <div className="content " style={{width:'97vw'}}>
            <h1 className="mb-3 text-capitalize">{__('Medical')}</h1>
                <div className="row">

                    {tabShow == 'reduction_number_yag'&&(
                    <div className="col-12" >
                        <h4>
                            {print_rep_b(__('The total number of yag would be reduced by {0} per year,'),
                                round(reductionOfNumOfYag[0] - reductionOfNumOfYag[1]))}{' '}
                            {print_rep_b(__('a {0}% reduction in the number of YAG'),
                                round((round(reductionOfNumOfYag[0] - reductionOfNumOfYag[1]) / reductionOfNumOfYag[0])*100,1)+'%',
                                '{0}%')}
                        </h4>
                        <h5>{__('Reduction of the number of yag')}</h5>
                        <div className="row">
                            <div className="col-7">
                                <div id="reduction_number_yag" style={{width:'100%', height:'56vh'}}></div>
                            </div>
                            <div className="col-5 d-flex align-items-center flex-column">

                                <button className="btn" data-toggle="collapse" style={{marginTop:'-3em'}} data-target="#p1_info"><IconInfo /></button>
                                <div className="collapse" id="p1_info" style={{zIndex:10}}>
                                    <div className="card card-body mt-4">
                                        <h6 className="font-weight-bold mb-4">{__('Nd:YAG laser rate by IOL type')}</h6>
                                        <table className="table table-sm">
                                            <tbody>
                                            {calcBaseProdData.map(({_id, name, calc, data_source:{study_name}})=>
                                                <tr key={_id}>
                                                    <td>{name}</td>
                                                    <td className="border border-primary">
                                                        {round(calc[`prob_YAG_caps_y${selectedYear}`]*100, 1)}%
                                                    </td>
                                                    <td>{study_name}</td>
                                                </tr>
                                            )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>)}

                    {(tabShow == 'dist_num_yag_by_type')&&(
                    <div className="col-12" >
                        <h5>{__('Distribution of the number of Nd:YAG procedures by type of IOL in the 2 current and future scenarios')}</h5>
                       <div className="row"><div className="col"></div><div className="col">
                       <h6 className="pl-5">
                                    {__('Reminder of the distribution of LIO volumes in the current and future scenarios')}</h6>
                           </div></div>
                        <div className="row">
                            <div className="col">
                                <div id="dist_num_yag_by_type" style={{width:'100%', height:'62vh'}}></div>
                            </div>
                            <div className="col">

                                <div id="dist_num_yag_by_type2" style={{width:'100%', height:'62vh'}}></div>
                            </div>
                        </div>

                    </div>)}
                        {(tabShow == 'tot_compl_YAG_current_future')&&(<div className="col-12 " >
                        <h5>{__('Total number of complications linked to YAG laser in the current and future scenarios')}</h5>

                        <p>
                            {print_rep_b(__('The total number of yag-related complications would be reduced by {0},'), round(
                                (Object.values(currentInterview.clinical_data).reduce((p, v)=>p + v * reductionOfNumOfYag[0], 0) -
                                Object.values(currentInterview.clinical_data).reduce((p,v)=>p + v * reductionOfNumOfYag[1], 0))/100))}{' '}
                            {print_rep_b(__('i.e. a {0}% reduction in complications'),round(
                                ((Object.values(currentInterview.clinical_data).reduce((p, v)=>p + v * reductionOfNumOfYag[0], 0) -
                                Object.values(currentInterview.clinical_data).reduce((p,v)=>p + v * reductionOfNumOfYag[1], 0))) /
                                Object.values(currentInterview.clinical_data).reduce((p, v)=>p + v * reductionOfNumOfYag[0], 0) *100 ,1)+'%','{0}%')}

                        </p>

                        <div className="row">
                            <div className="col-8">
                                <div id="tot_compl_YAG_current_future" style={{width:'100%', height:'56vh'}}></div>
                            </div>
                            <div className="col-4 d-flex align-items-center flex-column">
                                <button className="btn" data-toggle="collapse" data-target="#p2_info"><IconInfo /></button>
                                <div className="collapse" id="p2_info">
                                    <div className="card card-body mt-4">
                                        <h6 className="font-weight-bold mb-4">{__('Complications rate in the year following the Nd:YAG laser')}:</h6>
                                        <table className="table">
                                            <tbody>
                                            {Object.keys(currentInterview.clinical_data).map((k, i)=>
                                                <tr key={k}>
                                                    <td>{__(k)}</td>
                                                    <td className="border border-primary">{currentInterview.clinical_data[k]}%</td>
                                                </tr>
                                            )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>) }
            </div>

            {tabShow!='reduction_number_yag'&&
                (<button
                    className="position-fixed btn btn-link"
                    onClick={()=>{setTabShow(tabShow=='dist_num_yag_by_type'?'reduction_number_yag':'dist_num_yag_by_type')}}
                    style={{top:'10.2%',right:'4%'}}><IconPrev /></button>)}

            {tabShow!='dist_num_yag_by_type'&&
                (<button
                    className="position-fixed btn btn-link"
                    onClick={()=>{setTabShow('dist_num_yag_by_type')}}
                    style={{top:'10%',right:0}}><IconPlus /></button>)}

            {tabShow!='tot_compl_YAG_current_future'&&
                (<button
                    className="position-fixed btn btn-link"
                    onClick={()=>{setTabShow('tot_compl_YAG_current_future')}}
                    style={{top:'70%',right:'0'}}><IconArrow /></button>)}



        </div>
    )
}

export default DashboardGraph


const default_color = ['#00aeef', '#007483','#e5d4b7','#9bbb59','#767d82','#8ba29a','#01ad44','#713f94','#7f3f94']


const barChartOpt_current_future = (category, actual, future)=>({

    tooltip: {},
    legend: {},
    xAxis: {
        data: category
    },
    yAxis: {},
    series: [{
        name: __('Current'),
        type: 'bar',
        itemStyle:{
            color: default_color[0]
        },
        label:{
            show: true,
            rotate: 0,
            fontSize:14,
            color:'#FFF'
        },
        data: actual
    }, {
        name: __('Future'),
        type: 'bar',
        itemStyle:{
            color: default_color[1]
        },
        label:{
            show: true,
            rotate: 0,
            fontSize:14,
            color:'#FFF'
        },
        data: future
    }]
})

const barChartOpt = (category, data, yname, legend=true)=>({

    tooltip: {},
    legend:{show: legend, textStyle:{fontSize:10}, itemGap:6, itemWidth:18, align:'right', selectedMode:false},
    xAxis: [
        {data: category}
    ],
    yAxis: [
        {
            name: yname,
            type: 'value',
            nameLocation: 'center',
            nameGap:30,
            max: max(data.map(({value})=>value)) + 1
        }
    ],
    series: [
        ...data.map(({name, value}, i)=>(
        {
            name: name,
            type: 'bar',
            itemStyle:{
                color:  default_color[i]
            },
            label:{
                show: true,
                rotate: 90,
                fontSize:14,
                color:'#FFF'
            },
            data: value
        }))
    ]
})


const barChartOpt_c_f_m = (category, actual, middle, future)=>({

    tooltip: {},
    legend: {},
    xAxis: {
        data: category
    },
    yAxis: {},
    series: [{
        name: __('Current'),
        type: 'bar',
        itemStyle:{
            color: default_color[0]
        },
        label:{
            show: true,
            rotate: 0,
            fontSize:14,
            color:'#FFF',
            formatter: obj=>round(obj.value)
        },
        data: actual
    }, {
        type: 'scatter',
        symbolOffset : [0, '-100%'],
        symbolSize : 50,
        data: middle.map((o)=>max(o)),
        itemStyle:{ color: '#b3c82b' },
        label: {
            show: true,
            fontSize: 14,
            color: '#333',
            formatter: (obj) =>(round(middle[obj.dataIndex][0] - middle[obj.dataIndex][1]))
        }
    }, {
        name: __('Future'),
        type: 'bar',
        itemStyle:{
            color: default_color[1]
        },
        label:{
            show: true,
            rotate: 0,
            fontSize:14,
            color:'#FFF',
            formatter: obj=>round(obj.value)
        },
        data: future
    }]
})



const IconInfo = () => (
    <svg width="3em" height="3em" className="svg-inline--fa text-primary" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path>
    </svg>
)

const IconPlus = () => (
    <svg width="2.5em" height="2.5em" className="svg-inline--fa text-primary" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
        <path fill="currentColor" d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path>
    </svg>
)

const IconPrev = () => (
    <svg  width="2.2em" height="2.2em" className="svg-inline--fa fa-angle-double-left fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
        <path fill="currentColor" d="M223.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L319.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L393.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34zm-192 34l136 136c9.4 9.4 24.6 9.4 33.9 0l22.6-22.6c9.4-9.4 9.4-24.6 0-33.9L127.9 256l96.4-96.4c9.4-9.4 9.4-24.6 0-33.9L201.7 103c-9.4-9.4-24.6-9.4-33.9 0l-136 136c-9.5 9.4-9.5 24.6-.1 34z"></path>
    </svg>
)

const IconArrow = () => (
    <img src="/assets/images/next.png" style={{width:'5em'}} />
)
