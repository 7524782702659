/** Auth actions */
export const AUTH_LOGIN = 'AUTH_LOGIN'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const AUTH_REFRESH = 'AUTH_REFRESH'
export const AUTH_STORED = 'AUTH_STORED'
export const AUTH_ERROR = 'AUTH_ERROR'

export const login = ({user, token, role}) => ({
    type: AUTH_STORED,
    payload: {user, token, role}
})

export const logout = () => ({
    type: AUTH_LOGOUT,
    payload: ''
})

export const FORCE_RESET = 'FORCE_RESET'

/** Users actions */
export const USER_STORED = 'USER_STORED'
export const USER_UPDATE = 'USER_UPDATE'
export const USER_DELETE = 'USER_DELETE'
export const USER_FETCH = 'USER_FETCH'
export const USER_FETCH_ERROR = 'USER_FETCH_ERROR'

export const fetch_users = (filter={}) => ({
    type: USER_FETCH,
    payload: filter
})

export const delete_user = (_id) => ({
    type: USER_DELETE,
    payload: _id
})

export const update_user = (data) => ({
    type: USER_UPDATE,
    payload: data
})

/** Configurations actions */
export const CONFIGURATION_STORED = 'CONFIGURATION_STORED'
export const CONFIGURATION_FETCH = 'CONFIGURATION_FETCH'
export const CONFIGURATION_FETCH_ERROR = 'CONFIGURATION_FETCH_ERROR'
export const CONFIGURATION_UPDATE = 'CONFIGURATION_UPDATE'

export const fetch_configurations = (filter={}) => ({
    type: CONFIGURATION_FETCH
})

export const update_configurations = data => ({
    type: CONFIGURATION_UPDATE,
    payload: data
})

/** Products actions */
export const PRODUCT_STORED = 'PRODUCT_STORED'
export const PRODUCT_DELETE = 'PRODUCT_DELETE'
export const PRODUCT_FETCH = 'PRODUCT_FETCH'
export const PRODUCT_UPDATE = 'PRODUCT_UPDATE'
export const PRODUCT_FETCH_ERROR = 'PRODUCT_FETCH_ERROR'

export const fetch_products = (filter={}) => ({
    type: PRODUCT_FETCH,
    payload: filter
})

export const delete_product = (_id) => ({
    type: PRODUCT_DELETE,
    payload: _id
})

export const store_product = (data) => ({
    type: PRODUCT_STORED,
    payload: data
})

export const update_product = data => ({
    type: PRODUCT_UPDATE,
    payload: data
})

/** Interviews actions */
export const INTERVIEW_UPDATE = 'INTERVIEW_UPDATE'

export const update_inteview = data => ({
    type: INTERVIEW_UPDATE,
    payload: data
})

export const HISTORYSTUDY_FETCH = 'HISTORYSTUDY_FETCH'
export const HISTORYSTUDY_FETCH_ERROR = 'HISTORYSTUDY_FETCH_ERROR'
export const HISTORYSTUDY_STORED = 'HISTORYSTUDY_STORED'
export const HISTORYSTUDY_UPDATE = 'HISTORYSTUDY_UPDATE'

export const fetch_history_study_data = data => ({
    type: HISTORYSTUDY_FETCH,
    payload: data
})

export const SAVE_DATA = 'SAVE_DATA'
export const NEW_INTERVIEW = 'NEW_INTERVIEW'
export const SAVE_QUEUE = 'SAVE_QUEUE'
export const POOLING_SAVE = 'POOLING_SAVE'
export const EMPTY_QUEUE = 'EMPTY_QUEUE'

export const RESULT_LOAD = 'RESULT_LOAD'

export const CHANGE_TYPE_CONFIG = 'CHANGE_TYPE_CONFIG'
