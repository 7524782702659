import { call, put, takeEvery, takeLatest, select } from 'redux-saga/effects'

import { toast } from 'react-toastify';

import {
    AUTH_LOGIN, AUTH_ERROR, AUTH_STORED,AUTH_LOGOUT,
    USER_FETCH, USER_FETCH_ERROR, USER_STORED, USER_DELETE, USER_UPDATE,
    CONFIGURATION_FETCH, CONFIGURATION_STORED, CONFIGURATION_FETCH_ERROR,CONFIGURATION_UPDATE,
    HISTORYSTUDY_FETCH,HISTORYSTUDY_FETCH_ERROR, HISTORYSTUDY_STORED,
    PRODUCT_FETCH, PRODUCT_FETCH_ERROR, PRODUCT_STORED, PRODUCT_DELETE, PRODUCT_UPDATE,
    SAVE_DATA, NEW_INTERVIEW, SAVE_QUEUE, POOLING_SAVE, EMPTY_QUEUE, RESULT_LOAD
} from '../actions'

import api from './api'


const __ = str=>str

/* ------ primary function > list middleware -------- */
function* middlewareList() {
    yield takeLatest(AUTH_LOGIN, authUser);
    yield takeLatest(AUTH_LOGOUT, authLogout);

    yield takeEvery(USER_FETCH, fetchUsers);
    yield takeEvery(PRODUCT_FETCH, fetchProducts);
    yield takeEvery(CONFIGURATION_FETCH, fetchConfigurations)
    yield takeEvery(HISTORYSTUDY_FETCH, fetchHistoryStudy)

    yield takeLatest(USER_DELETE, deleteUser)
    yield takeLatest(PRODUCT_DELETE, deleteProduct)

    yield takeEvery(USER_UPDATE, updateUser)
    yield takeEvery(CONFIGURATION_UPDATE, updateConfiguration)
    yield takeEvery(PRODUCT_UPDATE, updateProduct)
    yield takeEvery(SAVE_DATA, saved_data)

    yield takeEvery(POOLING_SAVE, poolingSaved)

    yield takeEvery(RESULT_LOAD, get_result)

    yield takeEvery("persist/REHYDRATE", rehydrate_api_token)
  }

  export default middlewareList;


/* ----------  list api call ------------ */

const login_call = data => (api.post('auth/login', data))
const logout_call = () => (api.post('auth/logout'))
const my_profile_call = () => (api.get('auth/profile'))
const getAuth = state => state.auth

const user_get_call = () => (api.get('user/get'))
const product_get_call = () => (api.get('product/get'))
const config_get_call = () => (api.get('configuration/get'))
const hostory_study_get_call = () => (api.get('historystudydata/get'))

const product_delete_call = id => (api.delete(`product/del/${id}`))
const user_delete_call = id => (api.delete(`user/del/${id}`))

const user_update_call = data => (api.post('user/set', data))
const configuration_update_call = data => (api.post('configuration/set',data))
const product_update_call = data => (api.post('product/set', data))

const saved_data_call = data => (api.post('interview', data))
const get_data_call = id => (api.get(`interview/${id}`))
const getCurrentInterview = state => state.interviews.current
const getQueueInterview = state => state.interviews.queue


/* ---------- Auth ---------------- */

function* authUser(action) {
    try {
        const auth = yield call(login_call, action.payload);
        api.defaults.headers.common['Authorization'] = `Bearer ${auth.data.token}`
        const profile = yield call(my_profile_call)
        yield put({type: AUTH_STORED, payload: {...auth.data, profile:profile.data}})

    } catch (error) {
      yield put({type: AUTH_ERROR, payload: error.response.data});
    }
 }

function* authLogout (){
    try {
        yield call(logout_call);
        toast.success('logged out')
    } catch (error) {
        toast.warning('session already expired')
    }


}

function* rehydrate_api_token(){
    const auth = yield select(getAuth)
    api.defaults.headers.common['Authorization'] = `Bearer ${auth.token}`
}


 /* ---------- Users --------------  */

function* fetchUsers(action) {

    try {
        const users = yield call(user_get_call)
        yield put({type: USER_STORED, payload: users.data})

    } catch (error) {HISTORYSTUDY_FETCH, HISTORYSTUDY_FETCH_ERROR
        yield put({type: USER_FETCH_ERROR, payload: error.response.data});
    }
}

function* deleteUser(action) {
    try {
        yield call(user_delete_call, action.payload)
        toast.success(__('user deleted'))
    } catch (error) {
        toast.warning(__('user not deleted')+`: ${error.response.data}`)
    }
}

function* updateUser(action) {

    try {
        const user = yield call(user_update_call, action.payload)
        yield put({type:USER_FETCH})
        toast.success(__('user saved'))
    } catch (error) {
        toast.warning(error.response.data.error)
    }
}

 /* ------------- Configurations --------------- */

function* fetchConfigurations(action){

    try {
        const config = yield call(config_get_call)
        yield put({type: CONFIGURATION_STORED, payload: config.data})
    } catch (error) {
        yield put({type: CONFIGURATION_FETCH_ERROR, payload: error.response.data});
    }
}

function* updateConfiguration(action){
    try {
        const user = yield call(configuration_update_call, action.payload)
        //yield put({type:CONFIGURATION_FETCH})
        toast.success(__('configuration saved'))
    } catch (error) {
        toast.warning(error.response.data.error)
    }
}

/* ---------- History Study ----------- */

function* fetchHistoryStudy(action) {
    try {
        const history_study = yield call(hostory_study_get_call)
        yield put({type: HISTORYSTUDY_STORED, payload: history_study.data})
    } catch (error) {
        yield put({type: HISTORYSTUDY_FETCH_ERROR, payload: error.response.data});
    }
}

/* ---------- Products --------------  */

function* fetchProducts(action) {

    try {
        const products = yield call(product_get_call)
        yield put({type: PRODUCT_STORED, payload: products.data})

    } catch (error) {
        toast.error(__('comunication error'))
        if(navigator.onLine && 'data' in error.response && error.response.data.error=='Unauthorized'){
            yield put({type: AUTH_LOGOUT})
        }
        yield put({type: PRODUCT_FETCH_ERROR, payload: error.response.data});
    }
 }

function* deleteProduct(action) {
    try {
        yield call(product_delete_call, action.payload)
        toast.success(__('product deleted'))
    } catch (error) {
        toast.warning(__('product not deleted')+`: ${error.response.data}`)
    }
}

function* updateProduct(action) {
    try {
        const prod = yield call(product_update_call, action.payload)
        yield put({type:PRODUCT_FETCH})
        toast.success(__('product saved'))
    } catch (error) {
        toast.warning(error.response.data.error)
    }
}


function* poolingSaved(){
    const interviewQueue = yield select(getQueueInterview)
    const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;

    try {
        if(connection.downlink != 0){
            console.log('Saved all interviews queue')
            yield call(saved_data_call, interviewQueue)
            yield put({type:EMPTY_QUEUE})
        }else console.log('no connect, waiting')
    } catch (error) {
        console.log('no-connect, error', connection.downlink)
    }

}


function* saved_data(action){
    const interview = yield select(getCurrentInterview)
    const interviewQueue = yield select(getQueueInterview)
    const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;

    try {
        if(connection.downlink == 0){
            console.log('offline, add to queue')
            yield put({type:SAVE_QUEUE, payload: interview})
        }else{
            console.log('update db')
            yield call(saved_data_call, interview)
            yield interviewQueue.map((obj)=>{
                call(saved_data_call, obj)
            })
        }
    } catch (error) {
        console.log('no-connect', connection.downlink)

        toast.warning(error.response.data.error)
    }
}



function* get_result(action){
    if(typeof action.payload != 'string') return
    try{
        const data = yield call(get_data_call, action.payload )
        yield put({type:RESULT_LOAD, payload: data.data})
    }catch(error){
        console.table(error)
    }
}
