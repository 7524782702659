import {AUTH_STORED, AUTH_LOGOUT, AUTH_REFRESH, AUTH_ERROR} from '../actions'


const initialState = {
    user: {},
    token: '',
    role: '',
    error: {}

};

  export default (state = initialState, action) => {
    switch (action.type) {
      case AUTH_STORED: {
        return {
          ...state,
          ...action.payload,
          ...{error:{}}
        };
      }

      case AUTH_LOGOUT: {
        return {...initialState};
      }

      case AUTH_ERROR:
          return {...state, ...{error: action.payload}}

      default: {
        return state;
      }
    }
  };
