import React, {useEffect, useState, useRef, useMemo} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {round, log, exp, evaluate, map, max} from 'mathjs'
import {format_num} from './global_tool'
/** charts */
import echarts from 'echarts/lib/echarts'
// include bar chart
import 'echarts/lib/chart/bar'
// include tooltip and title component
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/title'
import 'echarts/lib/component/legend'

import __ from '../lang'


const print_rep_b = (str, txt, what='{0}') => {
    let split_str = str.split(what)
    split_str.splice(1, 0, <b key="l1">{txt}</b>)
    return split_str
}

const DashboardPublicHealth = ({currentInterview, reductionOfNumOfYag, configType, timeCataractSurgery, timeConsultations}) => {

    const [tabShow, setTabShow] = useState('chart_0')

    const data_chart2 = useMemo(()=>{
        return (
        [
            round((reductionOfNumOfYag[1] - reductionOfNumOfYag[0]) * currentInterview.costs_resources.laser_q,1),

            round(Object.keys(currentInterview.clinical_data).reduce((p, k)=>{
                let cl_d = currentInterview.clinical_data[k]
                let cl_d_num = currentInterview.costs_resources[`${k}_num`]
                let c_res = currentInterview.costs_resources[`${k}_q`]||0

                return(p + ((((reductionOfNumOfYag[1]*cl_d / 100) * cl_d_num) - (reductionOfNumOfYag[0]*cl_d / 100) * cl_d_num)) * c_res )
            },0),1),

            round((reductionOfNumOfYag[1] - reductionOfNumOfYag[0]) *
            parseFloat(currentInterview.costs_resources.consult_q) *
            parseInt(currentInterview.costs_resources.laser_num),1)
        ]
    )},[currentInterview.costs_resources.consult_q])

    let chart_1,chart_2,chart_3, chart_0

    useEffect(()=>{
        if(tabShow == 'chart_0'){
            chart_0 = echarts.init(document.getElementById('chart_0'))
            chart_0.setOption(barChartOpt(
                [__('Cataract Surgery'), __('Consultations') ],
                [timeCataractSurgery, timeConsultations]
            ))
        }
        if(tabShow == 'chart_1'){
            chart_1 = echarts.init(document.getElementById('chart_1'))
            chart_1.setOption(barChartOpt_eur(
                    [
                        'Chirurgies de la cataracte supplémentaires',
                        'Consultations supplémentaires',
                        'Total'
                    ],
                    [
                        round(timeCataractSurgery * parseFloat(currentInterview.costs_resources.cataract_q)),
                        round(timeConsultations * parseFloat(currentInterview.costs_resources.consult_q)),
                        round(timeCataractSurgery * parseFloat(currentInterview.costs_resources.cataract_q)) +
                            round(timeConsultations * currentInterview.costs_resources.consult_q)
                    ]
            ))
        }

        if(tabShow == 'chart_2'){
            chart_2 = echarts.init(document.getElementById('chart_2'))
            chart_2.setOption(barChartOpt_eur(
                [
                    'Réduction du nombre de YAG ',
                    'Réduction des complications',
                    'Réduction des visites de suivi post Yag',
                    'Total'
                ],
                [ ...data_chart2, round(data_chart2.reduce((p, o)=>p + o)) ]
            ))
        }

        if(tabShow == 'chart_3'){
            chart_3 = echarts.init(document.getElementById('chart_3'))
            chart_3.setOption(barChartOpt_eur([
                "Chirurgies et consultations supplémentaires",
                "Perte de revenus liée à la réduction des Yag",
                "Total"],[
                    round(timeCataractSurgery * currentInterview.costs_resources.cataract_q) +
                    round(timeConsultations * currentInterview.costs_resources.consult_q),
                    round(data_chart2.reduce((p, o)=>p + o)),
                    round((timeCataractSurgery * currentInterview.costs_resources.cataract_q) +
                        (timeConsultations * currentInterview.costs_resources.consult_q) +
                        data_chart2.reduce((p, o)=>p + o))
                ]
            ))
        }
    }, [tabShow])


    return (
        <div className="content " style={{width:'97vw'}}>
            <h3 className="mb-3">Financier – Perspective du chirurgien</h3>

            {tabShow == 'chart_0'&&(
                <div className="col-12" >
                    <h4 className="mb-3">
                        {__("I choose how I allocate the time saved into additional procedures (%):")} <br />
                        <i>{__('Cataract Surgery')}</i>: <b>{currentInterview.splitTime.cataract}%</b> {' - '}
                        <i>{__('Consultation')}</i>: <b>{currentInterview.splitTime.consult}%</b>
                    </h4>
                    <h5>{__('Number of additional acts')}</h5>

                    <div className="row">
                        <div className="col-8">
                            <div id="chart_0" style={{width:'100%', height:'60vh'}}></div>
                        </div>
                        <div className="col-4 d-flex align-items-center flex-column">

                            <button className="btn" data-toggle="collapse" data-target="#p1_info"><IconInfo /></button>
                            <div className="collapse" id="p1_info">
                                <div className="card mt-4 px-4">

                                    <table className="table table-sm">
                                        <thead><tr><th className="text-right" colSpan={2}>{__('Time (minutes)')}</th></tr></thead>
                                        <tbody>
                                            <tr>
                                                <td>{__('Duration of a consultation')}</td>
                                                <td className="border border-primary">
                                                    {currentInterview.costs_resources.duration_consultion}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{__('Duration of a Nd: YAG laser')}</td>
                                                <td className="border border-primary">
                                                    {currentInterview.costs_resources.duration_yag_laser}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{__('Duration of cataract surgery')}</td>
                                                <td className="border border-primary">
                                                    {currentInterview.costs_resources.duration_cataract}
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)}

            {tabShow == 'chart_1'&&(
                <div><h4>{__('Added revenue for the surgeon  generated by the added procedures')}</h4>

                <div className="row">
                            <div className="col-8">
                                <div id={tabShow} style={{width:'100%', height:'60vh'}}></div>
                            </div>
                            <div className="col-4 d-flex align-items-center flex-column">

                                <button className="btn" data-toggle="collapse" data-target="#p1_info"><IconInfo /></button>
                                <div className="collapse" id="p1_info">
                                    <div className="card mt-4 px-4">

                                        <table className="table">
                                            <thead><tr><th className="text-right" colSpan={2}>{__('Revenue')}</th></tr></thead>
                                            <tbody>
                                                <tr>
                                                    <td>{__('Cataract Surgery')}</td>
                                                    <td className="border border-primary">
                                                        {currentInterview.costs_resources.cataract_q||0} €
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>{__('Consultations')}</td>
                                                    <td className="border border-primary">
                                                        {currentInterview.costs_resources.consult_q||0} €
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                </div></div>
            )}
            {tabShow == 'chart_2'&&(
                <div><h4>{__('Lost revenue linked to the reduction of YAG and follow up visits')}</h4>
                <div className="row">
                            <div className="col-8">
                                <div id={tabShow} style={{width:'100%', height:'60vh'}}></div>
                            </div>
                            <div className="col-4 d-flex align-items-center pr-5">

                            </div>
                </div></div>
            )}
            {tabShow == 'chart_3'&&(
                <div>
                    <h5>{__('Synthesis of  revenue for the surgeon between the added procedures and the lost one')}</h5>

                    <div className="row">
                                <div className="col-8">
                                    <div id={tabShow} style={{width:'100%', height:'60vh'}}></div>
                                </div>
                                <div className="col-4 d-flex align-items-center pr-5">
                                    <h4>
                                        {print_rep_b('Ces procédures supplémentaires généreraient un revenu additionnel total pour le chirurgien de: {0}',
                                        format_num(round(round((timeCataractSurgery * currentInterview.costs_resources.cataract_q) +
                                        (timeConsultations * currentInterview.costs_resources.consult_q) +
                                        data_chart2.reduce((p, o)=>p + o))))+'€')}
                                    </h4>
                                </div>
                    </div></div>
            )}


            {tabShow!='chart_0'&&
                (<button
                    className="position-fixed btn btn-link"
                    onClick={()=>{
                        let t = tabShow.split('_');

                        setTabShow(t[0]+'_'+(parseInt(t[1])-1)) }}
                    style={{top:'10.2%',right:'4%'}}><IconPrev /></button>)}

            {tabShow!='chart_3'&&
                (<button
                    className="position-fixed btn btn-link"
                    onClick={()=>{
                        let t = tabShow.split('_');
                        setTabShow(t[0]+'_'+(parseInt(t[1])+1))}}
                    style={{top:'70%',right:'2%'}}><IconArrow /></button>)}


        </div>
    )
}

export default DashboardPublicHealth


const default_color = ['#00aeef', '#007483','#e5d4b7','#9bbb59','#9bbb59','#ff0000','#01ad44','#713f94']

const barChartOpt_eur = (category, data)=>({

    tooltip: { formatter: (o)=>(o.value+'€') },
    legend:{show:true},
    xAxis: {
        data: category,
        axisLabel:{
            fontSize: 11,
            interval: 0,
            formatter: (v) => (v.split(' ').map((v,i) =>i%2?v+"\n":v ).join(' '))
        }
    },

    yAxis: { formatter: (o)=>(format_num(o.value)+'€') },
    series: [{
            name: name,
            type: 'bar',
            itemStyle:{
                color: (o)=>default_color[o.dataIndex==data.length - 1?3:0]
            },
            label:{
                show: true,
                rotate: 0,
                fontSize:20,
                color:'#FFF',
                formatter: (o)=>(format_num(o.value)+'€')
            },
            data: data
        }]

})


const barChartOpt = (category, data)=>({

    tooltip: {},
    legend:{},
    xAxis: {
        data: category
    },
    yAxis: {},
    series: [{
            name: name,
            type: 'bar',
            itemStyle:{
                color: default_color[0]
            },
            label:{
                show: true,
                rotate: 0,
                fontSize:18,
                color:'#FFF'
            },
            data: data
        }]

})

const IconInfo = () => (
    <svg width="3em" height="3em" className="svg-inline--fa text-primary" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path>
    </svg>
)

const IconPlus = () => (
    <svg width="2.5em" height="2.5em" className="svg-inline--fa text-primary" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
        <path fill="currentColor" d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path>
    </svg>
)

const IconPrev = () => (
    <svg  width="2.2em" height="2.2em" className="svg-inline--fa fa-angle-double-left fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
        <path fill="currentColor" d="M223.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L319.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L393.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34zm-192 34l136 136c9.4 9.4 24.6 9.4 33.9 0l22.6-22.6c9.4-9.4 9.4-24.6 0-33.9L127.9 256l96.4-96.4c9.4-9.4 9.4-24.6 0-33.9L201.7 103c-9.4-9.4-24.6-9.4-33.9 0l-136 136c-9.5 9.4-9.5 24.6-.1 34z"></path>
    </svg>
)

const IconArrow = () => (
    <img src="/assets/images/next.png" style={{width:'5em'}} />

)
