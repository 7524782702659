import React, {useEffect, useState, useRef, useMemo} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { update_inteview } from '../actions'
import DashboardGraph from './dashboard_graph'
import DashboardPublicHealth from './dashboard_public_health'
import DashboardEfficient from './dashboard_efficient'
import DashboardFinancial from './dashboard_financial'
import DashboardBudgetary from './dashboard_budgetary'
import FinalSummary from './final_summary'

import {round, log, exp, floor, ceil, max} from 'mathjs'

import __ from '../lang'


const DashboardResult = ({currentInterview, configType})=>{

    const products = useSelector(({products})=>products.list).filter(({_id})=>currentInterview.selectedProd.includes(_id))
    const constants = useSelector(({configurations})=>configurations.list)
    const selected_year = constants.find(({name})=>name=='clinical_year_by').value
    const dispatch = useDispatch()

    const calcBaseProdData = useMemo(()=>(
        products.map((p)=>{
            let prob_YAG = (p.data_source.yag_capsulotomy / p.data_source.total_eyes)
            let avg_YAG_per_month = -log(1 - prob_YAG) / p.data_source.time_point
            let calc = {
                prob_YAG,
                avg_YAG_per_month,
                prob_YAG_caps_y1: 1-exp(-parseInt(constants.find(({name})=>name=='min_1y').value) * (avg_YAG_per_month)), // pr_NdYAG_y1
                prob_YAG_caps_y2: 1-exp(-parseInt(constants.find(({name})=>name=='min_2y').value) * (avg_YAG_per_month)), // pr_NdYAG_y2
                prob_YAG_caps_y3: 1-exp(-parseInt(constants.find(({name})=>name=='min_3y').value) * (avg_YAG_per_month)), // pr_NdYAG_y3
            }
            return {...p, calc}
        })
    ), [products.map(({_id})=>_id)])

    const reduction_of_num_of_yag = useMemo(()=>(

        currentInterview.custom_avg.list.reduce((prev, {_id, actual_avg, future_avg})=>{
            let ndYag = (calcBaseProdData.find((p)=>p._id==_id)||{}).calc[`prob_YAG_caps_y${selected_year}`]
            const act_pr_usage = parseFloat(actual_avg.pr_usage)
            const fut_pr_usage = parseFloat(future_avg.pr_usage)
            let sc1_actual = currentInterview.type=='number'?
                                actual_avg.number : act_pr_usage?(parseInt(currentInterview.custom_avg.actual_cataract) * (act_pr_usage / 100)):0
            let sc1_future = currentInterview.type=='number'?
                                future_avg.number : fut_pr_usage?(parseInt(currentInterview.custom_avg.future_cataract) * (fut_pr_usage / 100)):0
            return [prev[0]+(ndYag*sc1_actual), prev[1]+(ndYag*sc1_future)]
        } ,[0, 0])

    ),[calcBaseProdData.map(({_id})=>_id), currentInterview.type])

    let tot_efficient_current_future, timeCataractSurgery, timeConsultations,timeCataractSurgery_pr, timeConsultations_pr

    tot_efficient_current_future = useMemo(()=>(reduction_of_num_of_yag.map((rnyag, i) => {
        let time = 60
        let p_cent = 0.01
        let consult_div_time = currentInterview.costs_resources.duration_consultion / time

        let time_perform_YAG_procedure = rnyag * currentInterview.costs_resources.duration_yag_laser / time
        let followup_visits_post_YAG = rnyag * currentInterview.costs_resources.laser_num * consult_div_time

        let vitrectomy = rnyag * p_cent * currentInterview.clinical_data.vitrectomy *
                        currentInterview.costs_resources.vitrectomy_num * consult_div_time
        let glaucoma_treatment = rnyag * p_cent * currentInterview.clinical_data.glaucoma *
                                currentInterview.costs_resources.glaucoma_num * consult_div_time
        let omc_treatment = rnyag * p_cent * currentInterview.clinical_data.omc *
                            currentInterview.costs_resources.omc_num * consult_div_time

        return [time_perform_YAG_procedure,followup_visits_post_YAG,vitrectomy,glaucoma_treatment,omc_treatment]
        })),
        [reduction_of_num_of_yag])

    timeCataractSurgery =  useMemo(()=>(
        round(ceil(tot_efficient_current_future[0].reduce((p,v,i)=>(v-tot_efficient_current_future[1][i] + p),0)) * 6 *
                        currentInterview.splitTime.cataract / currentInterview.costs_resources.duration_cataract * 0.1,1)
        ), [tot_efficient_current_future[0],currentInterview.splitTime.cataract, currentInterview.costs_resources.duration_cataract])

    timeConsultations =  useMemo(()=>(
        floor(ceil(tot_efficient_current_future[0].reduce((p,v,i)=>(v-tot_efficient_current_future[1][i] + p),0)) * 6 *
                        currentInterview.splitTime.consult / currentInterview.costs_resources.duration_consultion * 0.1)
    ),[tot_efficient_current_future[0], currentInterview.splitTime.consult, currentInterview.costs_resources.duration_consultion])

    timeCataractSurgery_pr =  useMemo(()=>(
        floor(ceil(tot_efficient_current_future[0].slice(0,2).reduce((p,v,i)=>(v-tot_efficient_current_future[1][i] + p),0)) * 6 *
                        currentInterview.splitTime.cataract / currentInterview.costs_resources.duration_cataract * 0.1)
        ), [tot_efficient_current_future[0],currentInterview.splitTime.cataract, currentInterview.costs_resources.duration_cataract])

    timeConsultations_pr =  useMemo(()=>(
        floor(ceil(tot_efficient_current_future[0].slice(0,2).reduce((p,v,i)=>(v-tot_efficient_current_future[1][i] + p),0)) * 6 *
                        currentInterview.splitTime.consult / currentInterview.costs_resources.duration_consultion * 0.1)
    ),[tot_efficient_current_future[0], currentInterview.splitTime.consult, currentInterview.costs_resources.duration_consultion])


    useEffect(()=>{

        dispatch(update_inteview({selectedYear: selected_year, pre_calc: {
                                    reductionOfNumOfYag: reduction_of_num_of_yag,
                                    calcBaseProdData}}))
    }, [])


    const content = () =>{
        switch (currentInterview.step){
            case 5:
                return <DashboardGraph
                            currentInterview={currentInterview}
                            products={products}
                            calcBaseProdData={calcBaseProdData}
                            selectedYear={selected_year}
                            reductionOfNumOfYag={reduction_of_num_of_yag} />
            case 6:
                return <DashboardPublicHealth
                            currentInterview={currentInterview}
                            products={products}
                            calcBaseProdData={calcBaseProdData}
                            selectedYear={selected_year}
                            reductionOfNumOfYag={reduction_of_num_of_yag} />
            case 7:
                return <DashboardEfficient
                            currentInterview={currentInterview}
                            products={products}
                            configType={configType}
                            calcBaseProdData={calcBaseProdData}
                            selectedYear={selected_year}
                            reductionOfNumOfYag={reduction_of_num_of_yag} />
            case 8:
                return <DashboardFinancial
                            currentInterview={currentInterview}
                            products={products}
                            calcBaseProdData={calcBaseProdData}
                            selectedYear={selected_year}
                            timeCataractSurgery={configType=='private'? timeCataractSurgery_pr : timeCataractSurgery}
                            timeConsultations={configType=='private' ? timeConsultations_pr : timeConsultations}
                            configType={configType}
                            reductionOfNumOfYag={reduction_of_num_of_yag} />
            case 9:
                return <DashboardBudgetary
                            currentInterview={currentInterview}
                            products={products}
                            calcBaseProdData={calcBaseProdData}
                            selectedYear={selected_year}
                            timeCataractSurgery={timeCataractSurgery_pr}
                            timeConsultations={timeConsultations_pr}
                            reductionOfNumOfYag={reduction_of_num_of_yag} />
            case 10:
                return <FinalSummary
                            currentInterview={currentInterview}
                            products={products}
                            calcBaseProdData={calcBaseProdData}
                            selectedYear={selected_year}
                            timeCataractSurgery={configType=='private'? timeCataractSurgery_pr : timeCataractSurgery}
                            timeConsultations={configType=='private' ? timeConsultations_pr : timeConsultations}
                            reductionOfNumOfYag={reduction_of_num_of_yag}
                            configType={configType} />
        }
    }

    return (
        <div className="overflow-hidden">
            {content()}
        </div>
    )
}

export default DashboardResult
