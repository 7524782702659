import React, {useEffect, useState, useRef, useMemo} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { update_inteview, SAVE_DATA, NEW_INTERVIEW } from '../actions'
import {round, log, exp, floor, ceil, max, intersect} from 'mathjs'
import { toast } from 'react-toastify';
import moment from 'moment'
import __ from '../lang'
import {format_num} from './global_tool'


const FinalSummary = ({currentInterview, configType, reductionOfNumOfYag,
                        timeCataractSurgery, timeConsultations, selectedYear})=>{

    const dispatch = useDispatch()

    const [showSpinner, setShowSpinner] = useState(false)
    const [resultSelected, setResultSelected] = useState([])

    const email = useRef()
    const email_self = useRef()

    const calcBaseProdData = currentInterview.pre_calc || {} ? currentInterview.pre_calc.calcBaseProdData : {}

    let tot_compl_YAG_current_future = useMemo(()=>( reductionOfNumOfYag.map((rnyag, i) => {
        let const_complic_vetrectomy = rnyag * currentInterview.clinical_data.vitrectomy //(E22)*(pr_complications)
        let const_complic_glaucoma = rnyag * currentInterview.clinical_data.glaucoma //(E22)*(pr_complications)
        let const_complic_omc = rnyag * currentInterview.clinical_data.omc //(E22)*(pr_complications)

        let total_cost_vitrectomy = round(const_complic_vetrectomy * currentInterview.costs_resources.vitrectomy / 100,2); //(c_complication)
        let total_cost_glaucoma = round(const_complic_glaucoma * currentInterview.costs_resources.glaucoma / 100,2);
        let total_cost_omc = round(const_complic_omc * currentInterview.costs_resources.omc / 100,2);

        let total_cost_yag = currentInterview.custom_avg.list.reduce((p,
                                    {_id, actual_avg:{number: num_act,pr_usage:pru_act}, future_avg:{number: num_fut,pr_usage:pru_fut}})=>{
                let a,f
                if(currentInterview.type=='number'){
                    a = num_act
                    f = num_fut
                }else{
                    a = parseFloat(pru_act)?(parseInt(currentInterview.custom_avg.actual_cataract) * (parseFloat(pru_act) / 100)):0
                    f = parseFloat(pru_fut)?(parseInt(currentInterview.custom_avg.actual_cataract) * (parseFloat(pru_fut) / 100)):0
                }

                let ndYag = (calcBaseProdData.find((p)=>p._id==_id)||{}).calc[`prob_YAG_caps_y${selectedYear}`]
                return (
                    [(a * ndYag * currentInterview.costs_resources.laser) + p[0],
                    (f * ndYag * currentInterview.costs_resources.laser) + p[1]]
            )},[0, 0])


        let total_cost_visit = (rnyag * currentInterview.costs_resources.laser_num  +
                            const_complic_vetrectomy * 0.01 * currentInterview.costs_resources.vitrectomy_num  +
                            const_complic_glaucoma * 0.01  * currentInterview.costs_resources.glaucoma_num  +
                            const_complic_omc * 0.01 * currentInterview.costs_resources.omc_num) *
                            currentInterview.costs_resources.cost_consult

        return [total_cost_yag[i], total_cost_visit, total_cost_vitrectomy, total_cost_glaucoma, total_cost_omc]
    })),[reductionOfNumOfYag])



    let tot_efficient_current_future = reductionOfNumOfYag.map((rnyag, i) => {
        let time = 60
        let p_cent = 0.01
        let consult_div_time = currentInterview.costs_resources.duration_consultion / time

        let time_perform_YAG_procedure = rnyag * currentInterview.costs_resources.duration_yag_laser / time
        let followup_visits_post_YAG = rnyag * currentInterview.costs_resources.laser_num * consult_div_time

        let vitrectomy = rnyag * p_cent * currentInterview.clinical_data.vitrectomy *
                        currentInterview.costs_resources.vitrectomy_num * consult_div_time
        let glaucoma_treatment = rnyag * p_cent * currentInterview.clinical_data.glaucoma *
                                currentInterview.costs_resources.glaucoma_num * consult_div_time
        let omc_treatment = rnyag * p_cent * currentInterview.clinical_data.omc *
                            currentInterview.costs_resources.omc_num * consult_div_time

        return [...[time_perform_YAG_procedure,followup_visits_post_YAG], ...(configType!='private'?[vitrectomy,glaucoma_treatment,omc_treatment]:[])]
    })

    let O22 = reductionOfNumOfYag.reduce((p, v)=>(v * currentInterview.costs_resources.laser - p),0)

    let O23 = reductionOfNumOfYag.reduce((p, v)=>{
        return ((v * currentInterview.clinical_data.vitrectomy * currentInterview.costs_resources.vitrectomy / 100) - p)
    },0)

    let O26 = Object.keys(currentInterview.clinical_data).reduce((p, k)=>(
        reductionOfNumOfYag[1] * currentInterview.clinical_data[k] * currentInterview.costs_resources[k] / 100 + p
    ),0) - Object.keys(currentInterview.clinical_data).reduce((p, k)=>(
        reductionOfNumOfYag[0] * currentInterview.clinical_data[k] * currentInterview.costs_resources[k] / 100 + p
    ),0) //treatment_complic_yag
                // E22                      v_ndyag                                     c_visit
    let O63 = reductionOfNumOfYag.reduce((p, ry)=>{

        return (
        ry * currentInterview.costs_resources.laser_num * currentInterview.costs_resources.cost_consult +
        Object.keys(currentInterview.clinical_data).reduce((pp, k)=>(
            currentInterview.clinical_data[k] * ry * currentInterview.costs_resources.cost_consult *
            currentInterview.costs_resources[`${k}_num`]  / 100 + pp), 0) - p
    )}, 0) // consult_visits_costs


    let prods_cost = currentInterview.custom_avg.list.reduce((p, obj)=>{
        let act, fut
        if(currentInterview.type=='number'){
            act = obj.actual_avg.number
            fut = obj.future_avg.number
        }else{
            act = parseFloat(obj.actual_avg.pr_usage)?(parseInt(currentInterview.custom_avg.actual_cataract) * (parseFloat(obj.actual_avg.pr_usage) / 100)):0
            fut = parseFloat(obj.future_avg.pr_usage)?(parseInt(currentInterview.custom_avg.actual_cataract) * (parseFloat(obj.future_avg.pr_usage) / 100)):0
        }
        return (
            p + (obj.future_avg.price * fut) - (obj.actual_avg.price * act)
        )}, 0)

// -------------------------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------------------------

    const data_chart2 = useMemo(()=>{
        return (
        [
            round((reductionOfNumOfYag[1] - reductionOfNumOfYag[0]) * currentInterview.costs_resources.laser_q,1),

            round(Object.keys(currentInterview.clinical_data).reduce((p, k)=>{
                let cl_d = currentInterview.clinical_data[k]
                let cl_d_num = currentInterview.costs_resources[`${k}_num`]
                let c_res = currentInterview.costs_resources[`${k}_q`]||0

                return(p + ((((reductionOfNumOfYag[1]*cl_d / 100) * cl_d_num) - (reductionOfNumOfYag[0]*cl_d / 100) * cl_d_num)) * c_res )
            },0),1),

            round((reductionOfNumOfYag[1] - reductionOfNumOfYag[0]) *
            parseFloat(currentInterview.costs_resources.consult_q) *
            parseInt(currentInterview.costs_resources.laser_num),1)
        ]
    )},[currentInterview.costs_resources.consult_q])


    let red_num_yag = round(reductionOfNumOfYag[0] - reductionOfNumOfYag[1])

    let red_compl_num_yag = round(
        (Object.values(currentInterview.clinical_data).reduce((p, v)=>p + v * reductionOfNumOfYag[0], 0) -
        Object.values(currentInterview.clinical_data).reduce((p,v)=>p + v * reductionOfNumOfYag[1], 0))/100)

    let health_saved = round(tot_compl_YAG_current_future[0].reduce((p,v)=>(p+v),0) - tot_compl_YAG_current_future[1].reduce((p,v)=>(p+v),0),2)+'€'

    let hours_saved = round(tot_efficient_current_future[0].reduce((p,v,i)=>(v-tot_efficient_current_future[1][i] + p),0)) + 'hr'

    let medical_estab =  round(timeCataractSurgery * currentInterview.costs_resources.cataract +
        (configType=='private'?0:timeConsultations * currentInterview.costs_resources.cost_consult)+
        (configType=='private'?(round(O23)):round(O22 + O26 + O63)) - prods_cost)+'€'



    let medical_estab_pr = round(round((timeCataractSurgery * currentInterview.costs_resources.cataract_q) +
                            (timeConsultations * currentInterview.costs_resources.consult_q) +
                            data_chart2.reduce((p, o)=>p + o)))+'€'


    const saved_data = () => {
        if(configType=='private' && !resultSelected){
            toast.warning(__('Need select clinical or surgery result'))
            return
        }
        dispatch(update_inteview({
                    email: email.current.value,
                    email_self: email_self.current.value,
                    ts: moment().format('YYYY-MM-DD HH:mm:ss'),
                    typeconfig: configType,
                    resultselected:resultSelected
        }))

        dispatch({type:SAVE_DATA})
        dispatch({type:NEW_INTERVIEW})
        setShowSpinner(true)
        toast.success(__('saved')+'!')
    }

    const resultSelectedInput = e => {
        if(e.target.checked)
            setResultSelected(Array.from(new Set([...resultSelected,e.target.value])))
        else
            setResultSelected(resultSelected.filter(o=>o!=e.target.value))

    }

    return (
        <div className="overflow-hidden final-summary w-75">
            <h1>{__('Synthesis of the simulation')}</h1>
            <hr />
            <div className="row">
                <div className="col">
                    {configType=='private'&&<h4>{__('clinical')}</h4>}
                    <ul className="list-group">
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                            {__('Reduction of the number of yag by')}: <b className="ml-4">{red_num_yag}</b>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                            {__('Reduction in the number of yag-related complications by')}: <b className="ml-4">{red_compl_num_yag}</b>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                            {__('Savings for Health Insurance of')}: <b className="ml-4 text-nowrap">{format_num(health_saved)}</b>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                            {__('Number of hours earned')}: <b className="ml-4">{hours_saved}</b>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                            {__('Additional income for the medical establishment of')}: <b className="ml-4 text-nowrap">{format_num(medical_estab)}</b>
                        </li>
                    </ul>
                    {false&&configType=='private'&&<div className="text-center"><input name="result_selected" type="checkbox" value="clinical" onChange={resultSelectedInput} /></div>}
                </div>
                {configType=='private'&&(<div className="col">
                    <h4>{__('surgery')}</h4>
                    <ul className="list-group">
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                            {__('Reduction of the number of yag by')}: <b className="ml-4">{red_num_yag}</b>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                            {__('Reduction in the number of yag-related complications by')}: <b className="ml-4">{red_compl_num_yag}</b>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                            {__('Savings for Health Insurance of')}: <b className="ml-4 text-nowrap">{format_num(health_saved)}</b>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                            {__('Number of hours earned')}: <b className="ml-4">{hours_saved}</b>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                            {__(configType=='private'?'Revenu supplémentaire pour le chirurgien':'Additional income for the medical establishment of')}: <b className="ml-4 text-nowrap">{format_num(medical_estab_pr)}</b>
                        </li>
                    </ul>
                    {false&&<div className="text-center"><input name="result_selected" type="checkbox" value="surgery" onChange={resultSelectedInput} /></div>}

                </div>)}
            </div>

            <br />
            <div className="card mt-2">
                <div className="card-header">
                    <h4>{__('Would you like to receive the results of this simulation by email? Please let us know:')}</h4>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-4">{__('Your email address')}:</div>
                        <div className="col">
                            <input name="email" type="email" className="form-control" placeholder="... email @" ref={email} />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-4">{__('personal copy email')}:</div>
                        <div className="col">
                            <input name="email_self" type="email" className="form-control" placeholder="... email @" ref={email_self} />
                        </div>
                    </div>
                    <h5>{__('The results you are interested in:')}</h5>
                    <ul className="list-group list-group-flush">
                    {[__('Medical'),  __('Health insurance point of view'), __('Time saving'),
                         ...(configType=='private'?[__('private Viewpoint of the medical establishment'),'Point de vue de la clinique']:[__('Viewpoint of the medical establishment')])].map((v,i)=>(

                        <li className="list-group-item" key={i}>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value={v} id={`interest${i}`} onChange={e=>{
                                    if(e.target.checked)
                                        dispatch(update_inteview({interest: [...currentInterview.interest, e.target.value]}))
                                    else
                                        dispatch(update_inteview({interest: currentInterview.interest.filter(vv=>vv!=e.target.value) }))
                                }}/>
                                <label className="form-check-label" htmlFor={`interest${i}`}>
                                    {v}
                                </label>
                            </div>
                        </li>
                    ))}
                    </ul>
                </div>
            </div>

            <button
                    className="position-fixed btn btn-link"
                    onClick={saved_data}
                    style={{top:'50%',right:'2%'}}><IconArrow /><br/>{__('Saved')}</button>
            {showSpinner&&(
                <div className="card bg-light position-fixed p-5" style={{top: '20%', left:'50%',transform:'translate(-50%, 50%)'}}>
                    <div className="spinner-border m-5" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    <h3>...{__('saved')}</h3>
                </div>
            )}
        </div>
    )
}

export default FinalSummary


const IconArrow = () => (
    <svg width="3.2em" height="3.2em" className="svg-inline--fa fa-long-arrow-alt-right fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
        <path fill="currentColor" d="M313.941 216H12c-6.627 0-12 5.373-12 12v56c0 6.627 5.373 12 12 12h301.941v46.059c0 21.382 25.851 32.09 40.971 16.971l86.059-86.059c9.373-9.373 9.373-24.569 0-33.941l-86.059-86.059c-15.119-15.119-40.971-4.411-40.971 16.971V216z"></path>
    </svg>
)
