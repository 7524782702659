import React, {useEffect, useState, useRef} from 'react';
import { useSelector, useDispatch } from "react-redux";
import {fetch_products, RESULT_LOAD} from '../actions'
import {round, log, exp, ceil, floor, max} from 'mathjs'

import { savePDF, PDFExport } from '@progress/kendo-react-pdf';

/** charts */
import echarts from 'echarts/lib/echarts'
// include bar chart
import 'echarts/lib/chart/bar'
import 'echarts/lib/chart/line'
import 'echarts/lib/chart/scatter'
// include tooltip and title component
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/title'
import 'echarts/lib/component/legend'
import __ from '../lang'
import {format_num} from '../interview/global_tool'




  const Result = ({id}) => {

    const dispatch = useDispatch()
    const currentInterview = useSelector(state => state.result);
    const {selectedYear} = currentInterview || {}
    const {reductionOfNumOfYag, calcBaseProdData} = currentInterview.pre_calc || {}
    const [finanicial_data, set_finanicial_data] = useState(['0','0'])

    const createPdf = (html) => html.save();//Doc.createPdf(html);
    const pages = useRef();


    useEffect(()=>{
        dispatch({type:RESULT_LOAD, payload: id})
        setTimeout(()=>{createPdf(pages.current)},1500)
    },[])


    let tot_compl_YAG_current_future, reduction_number_yag, dist_num_yag_by_type,dist_num_yag_by_type2,
        costs_scenarios, costs_scenarios2, costs_scenarios3, chart_1, chart_2, chart_3, chart_1_pr, chart_2_pr, chart_3_pr


        const ph_tot_compl_YAG_current_future = (reductionOfNumOfYag||[]).map((rnyag, i) => {
            let const_complic_vetrectomy = rnyag * currentInterview.clinical_data.vitrectomy //(E22)*(pr_complications)
            let const_complic_glaucoma = rnyag * currentInterview.clinical_data.glaucoma //(E22)*(pr_complications)
            let const_complic_omc = rnyag * currentInterview.clinical_data.omc //(E22)*(pr_complications)

            let total_cost_vitrectomy = round(const_complic_vetrectomy * currentInterview.costs_resources.vitrectomy / 100,2); //(c_complication)
            let total_cost_glaucoma = round(const_complic_glaucoma * currentInterview.costs_resources.glaucoma / 100,2);
            let total_cost_omc = round(const_complic_omc * currentInterview.costs_resources.omc / 100,2);

            let total_cost_yag = currentInterview.custom_avg.list.reduce((p,
                                        {_id, actual_avg:{number: num_act,pr_usage:pru_act}, future_avg:{number: num_fut,pr_usage:pru_fut}})=>{
                    let a,f
                    if(currentInterview.type=='number'){
                        a = num_act
                        f = num_fut
                    }else{
                        a = parseFloat(pru_act)?(parseInt(currentInterview.custom_avg.actual_cataract) * (parseFloat(pru_act) / 100)):0
                        f = parseFloat(pru_fut)?(parseInt(currentInterview.custom_avg.actual_cataract) * (parseFloat(pru_fut) / 100)):0
                    }

                    let ndYag = calcBaseProdData.find((p)=>p._id==_id).calc[`prob_YAG_caps_y${selectedYear}`]
                    return (
                        [(a * ndYag * currentInterview.costs_resources.laser) + p[0],
                        (f * ndYag * currentInterview.costs_resources.laser) + p[1]]
                )},[0, 0])


            let total_cost_visit = (rnyag * currentInterview.costs_resources.laser_num  +
                                const_complic_vetrectomy * 0.01 * currentInterview.costs_resources.vitrectomy_num  +
                                const_complic_glaucoma * 0.01  * currentInterview.costs_resources.glaucoma_num  +
                                const_complic_omc * 0.01 * currentInterview.costs_resources.omc_num) *
                                currentInterview.costs_resources.cost_consult

            return [total_cost_yag[i], total_cost_visit, total_cost_vitrectomy, total_cost_glaucoma, total_cost_omc]
        })

    const tot_efficient_current_future = (reductionOfNumOfYag || []).map((rnyag, i) => {
        let time = 60
        let p_cent = 0.01
        let consult_div_time = currentInterview.costs_resources.duration_consultion / time

        let time_perform_YAG_procedure = rnyag * currentInterview.costs_resources.duration_yag_laser / time
        let followup_visits_post_YAG = rnyag * currentInterview.costs_resources.laser_num * consult_div_time

        let vitrectomy = rnyag * p_cent * currentInterview.clinical_data.vitrectomy *
                        currentInterview.costs_resources.vitrectomy_num * consult_div_time
        let glaucoma_treatment = rnyag * p_cent * currentInterview.clinical_data.glaucoma *
                                currentInterview.costs_resources.glaucoma_num * consult_div_time
        let omc_treatment = rnyag * p_cent * currentInterview.clinical_data.omc *
                            currentInterview.costs_resources.omc_num * consult_div_time

        return [time_perform_YAG_procedure,followup_visits_post_YAG,vitrectomy,glaucoma_treatment,omc_treatment]
    })

    let timeCataractSurgery = 0
    let timeCataractSurgery_pr = 0
    let timeConsultations = 0
    let data_chart2 = []




            //E22                           // c_ndyag
            let O22 = !reductionOfNumOfYag?0: reductionOfNumOfYag.reduce((p, v)=>(v * currentInterview.costs_resources.laser - p),0)
            let O23 = !reductionOfNumOfYag?0:reductionOfNumOfYag.reduce((p, v)=>{
                return ((v * currentInterview.clinical_data.vitrectomy * currentInterview.costs_resources.vitrectomy / 100) - p)
            },0)
            let O26 = !reductionOfNumOfYag?0: Object.keys(currentInterview.clinical_data).reduce((p, k)=>(
                reductionOfNumOfYag[1] * currentInterview.clinical_data[k] * currentInterview.costs_resources[k] / 100 + p
            ),0) - Object.keys(currentInterview.clinical_data).reduce((p, k)=>(
                reductionOfNumOfYag[0] * currentInterview.clinical_data[k] * currentInterview.costs_resources[k] / 100 + p
            ),0) //treatment_complic_yag
                        // E22                      v_ndyag                                     c_visit
            let O63 = !reductionOfNumOfYag?0:reductionOfNumOfYag.reduce((p, ry)=>(
                ry * currentInterview.costs_resources.laser_num * currentInterview.costs_resources.cost_consult +
                Object.keys(currentInterview.clinical_data).reduce((pp, k)=>(
                    currentInterview.clinical_data[k] * ry * currentInterview.costs_resources.cost_consult *
                    currentInterview.costs_resources[`${k}_num`]  / 100 + pp), 0) - p
            ), 0) // consult_visits_costs

            let prods_cost = !reductionOfNumOfYag?0:currentInterview.custom_avg.list.reduce((p, obj)=>{
                let act, fut
                if(currentInterview.type=='number'){
                    act = obj.actual_avg.number
                    fut = obj.future_avg.number
                }else{
                    act = parseFloat(obj.actual_avg.pr_usage)?(parseInt(currentInterview.custom_avg.actual_cataract) * (parseFloat(obj.actual_avg.pr_usage) / 100)):0
                    fut = parseFloat(obj.future_avg.pr_usage)?(parseInt(currentInterview.custom_avg.actual_cataract) * (parseFloat(obj.future_avg.pr_usage) / 100)):0
                }
                return (
                    p + (obj.future_avg.price * fut) - (obj.actual_avg.price * act)
                )}, 0)

            let f_tot_efficient_current_future =!reductionOfNumOfYag ? 0 : reductionOfNumOfYag.map((rnyag, i) => {
                let time = 60
                let p_cent = 0.01
                let consult_div_time = currentInterview.costs_resources.duration_consultion / time

                let time_perform_YAG_procedure = rnyag * currentInterview.costs_resources.duration_yag_laser / time
                let followup_visits_post_YAG = rnyag * currentInterview.costs_resources.laser_num * consult_div_time

                let vitrectomy = rnyag * p_cent * currentInterview.clinical_data.vitrectomy *
                                currentInterview.costs_resources.vitrectomy_num * consult_div_time
                let glaucoma_treatment = rnyag * p_cent * currentInterview.clinical_data.glaucoma *
                                        currentInterview.costs_resources.glaucoma_num * consult_div_time
                let omc_treatment = rnyag * p_cent * currentInterview.clinical_data.omc *
                                    currentInterview.costs_resources.omc_num * consult_div_time

                return [...[time_perform_YAG_procedure,followup_visits_post_YAG], ...(currentInterview.typeconfig!='private'?[vitrectomy,glaucoma_treatment,omc_treatment]:[])]
            })

            timeCataractSurgery = !reductionOfNumOfYag? 0 : currentInterview.typeconfig!='private'
                                    ?
                        round(ceil(f_tot_efficient_current_future[0].reduce((p,v,i)=>(v-f_tot_efficient_current_future[1][i] + p),0)) * 6 *
                                        currentInterview.splitTime.cataract / currentInterview.costs_resources.duration_cataract * 0.1,1)
                                    :
                        floor(ceil(f_tot_efficient_current_future[0].slice(0,2).reduce((p,v,i)=>(v-f_tot_efficient_current_future[1][i] + p),0)) * 6 *
                                        currentInterview.splitTime.cataract / currentInterview.costs_resources.duration_cataract * 0.1)



            timeConsultations = !reductionOfNumOfYag?0:
                floor(ceil(f_tot_efficient_current_future[0].reduce((p,v,i)=>(v-f_tot_efficient_current_future[1][i] + p),0)) * 6 *
                                currentInterview.splitTime.consult / currentInterview.costs_resources.duration_consultion * 0.1)


            data_chart2 = !reductionOfNumOfYag?0: [
                round((reductionOfNumOfYag[1] - reductionOfNumOfYag[0]) * currentInterview.costs_resources.laser_q,1),

                round(Object.keys(currentInterview.clinical_data).reduce((p, k)=>{
                    let cl_d = currentInterview.clinical_data[k]
                    let cl_d_num = currentInterview.costs_resources[`${k}_num`]
                    let c_res = currentInterview.costs_resources[`${k}_q`]||0

                    return(p + ((((reductionOfNumOfYag[1]*cl_d / 100) * cl_d_num) - (reductionOfNumOfYag[0]*cl_d / 100) * cl_d_num)) * c_res )
                },0),1),

                round((reductionOfNumOfYag[1] - reductionOfNumOfYag[0]) *
                parseFloat(currentInterview.costs_resources.consult_q) *
                parseInt(currentInterview.costs_resources.laser_num),1)
            ]


    // session 1
    useEffect(()=>{
            if(!reductionOfNumOfYag) return

            let total_actual_custom_avg = currentInterview.custom_avg.list.reduce((p,{actual_avg})=>(
                p + (currentInterview.type=='number'?parseInt(actual_avg.number):(actual_avg.pr_usage * currentInterview.custom_avg.actual_cataract/100))),0)


            set_finanicial_data([format_num(round(timeCataractSurgery * currentInterview.costs_resources.cataract +
                (currentInterview.typeconfig=='private'?0:timeConsultations * currentInterview.costs_resources.cost_consult)+
                (currentInterview.typeconfig=='private'?(round(O23)):round(O22 + O26 + O63)) - prods_cost))+'€',

                round((timeCataractSurgery * currentInterview.costs_resources.cataract +
                    (currentInterview.typeconfig=='private'?0:timeConsultations * currentInterview.costs_resources.cost_consult)+
                    (currentInterview.typeconfig=='private'?(round(O23)):round(O22 + O26 + O63)) - prods_cost) /
                        (currentInterview.costs_resources.cataract * total_actual_custom_avg) *100
                ,2)+'%'])

              // porcodiochannel
              let chartspd = echarts.init(document.getElementById('current_future_pd'))
              let unitShow = currentInterview.type=='number'?'number':'pr_usage'

              chartspd.setOption(barChartOpt_c_f(
                  calcBaseProdData.map(({name})=>name),
                  currentInterview.custom_avg.list.map(({actual_avg})=>actual_avg[unitShow]*(unitShow=='number'?1:(currentInterview.custom_avg.actual_cataract/100))),
                  currentInterview.custom_avg.list.map(({future_avg})=>future_avg[unitShow]*(unitShow=='number'?1:(currentInterview.custom_avg.future_cataract/100)))
              ))


              let map_a = Object.values(currentInterview.clinical_data).map(v=>round(v*reductionOfNumOfYag[0]/100,1))
              let map_b = Object.values(currentInterview.clinical_data).map(v=>round(v*reductionOfNumOfYag[1]/100,1))
              tot_compl_YAG_current_future = new echarts.init(document.getElementById('tot_compl_YAG_current_future'))
              tot_compl_YAG_current_future.setOption(barChartOpt_c_f_m(
                          [__('vitrectomy'), __('Treatment of glaucoma'), __('Treatment of OMC')],
                          map_a,map_a.map((o,i)=>([o,map_b[i]])), map_b))



             reduction_number_yag = new echarts.init(document.getElementById('reduction_number_yag'))
             reduction_number_yag.setOption(barChartOpt_current_future(
                 [__('Number of Nd:YAG laser')],
                 [round(reductionOfNumOfYag[0])], [round(reductionOfNumOfYag[1])]
             ))


            // public heart
            costs_scenarios = echarts.init(document.getElementById('costs-scenarios'))
            costs_scenarios.setOption(ph_barChartOpt(
                    [
                        __("Total cost of procedures Nd:YAG"),
                        __("Total cost of consultation visits"),
                        __("Total cost of vitrectomy"),
                        __("Total cost of glaucoma treatment"),
                        __("Total cost of processing OMC")
                    ],
                    ph_tot_compl_YAG_current_future
            ))

            costs_scenarios2 = echarts.init(document.getElementById('costs-scenarios2'))
            costs_scenarios2.setOption(ph_barChartOpt_scenarios2(
                    [
                        __("Total cost of procedures Nd:YAG"),
                        __("Total cost of consultation visits"),
                        __("Total cost of vitrectomy"),
                        __("Total cost of glaucoma treatment"),
                        __("Total cost of processing OMC"),
                        __('Cost of complications (CME, vitrectomy, glaucoma)'),
                        __('Total earnings')
                    ],
                    [...ph_tot_compl_YAG_current_future[0].map((v,i) => (v-ph_tot_compl_YAG_current_future[1][i])),
                    ph_tot_compl_YAG_current_future[0].slice(-3).reduce((p,v)=>(p+v)) - ph_tot_compl_YAG_current_future[1].slice(-3).reduce((p,v)=>(p+v)),
                    ph_tot_compl_YAG_current_future[0].reduce((p,v)=>(p+v)) - ph_tot_compl_YAG_current_future[1].reduce((p,v)=>(p+v))
                    ]
            ))

            costs_scenarios3 = echarts.init(document.getElementById('costs-scenarios3'))
            costs_scenarios3.setOption(eff_barChartOpt(
                    [...[
                        __("Time to perform a Nd:YAG procedure"),
                        __("Follow-up visits post Nd:YAG")],
                        ...(currentInterview.typeconfig=='private'?[]:[
                        __("Vitrectomy"),
                        __("Glaucoma treatment"),
                        __("OMC treatment")])
                    ],
                    tot_efficient_current_future
            ))


            chart_3 = echarts.init(document.getElementById('chart_3'))
            chart_3.setOption(barChartOpt_eur(
                        [__("Income from additional procedures"),__("YAG income + associated complications"),
                            __("Difference IOL costs"),__("Total") ],
                        [
                            timeCataractSurgery * currentInterview.costs_resources.cataract +
                            (currentInterview.typeconfig=='private'?0:timeConsultations * currentInterview.costs_resources.cost_consult),
                            round((currentInterview.typeconfig=='private'?(round(O23)):round(O22 + O26 + O63))),
                            -prods_cost,
                            round(timeCataractSurgery * currentInterview.costs_resources.cataract +
                                (currentInterview.typeconfig=='private'?0:timeConsultations * currentInterview.costs_resources.cost_consult)+
                            (currentInterview.typeconfig=='private'?(round(O23)):round(O22 + O26 + O63)) - prods_cost)
                        ]))




            chart_1 = echarts.init(document.getElementById('chart_1'))
            chart_1.setOption(f_barChartOpt(
                [__('Cataract Surgery'), __('Consultations') ],
                [round(timeCataractSurgery), timeConsultations]
            ))

            if(currentInterview.typeconfig=='private'){

                chart_1_pr = echarts.init(document.getElementById('chart_1_pr'))
                chart_1_pr.setOption(f_barChartOpt(
                    [__('Cataract Surgery'), __('Consultations') ],
                    [round(timeCataractSurgery), timeConsultations]
                ))

                chart_3_pr = echarts.init(document.getElementById('chart_3_pr'))
                chart_3_pr.setOption(barChartOpt_eur([
                    "Chirurgies et consultations supplémentaires",
                    "Perte de revenus liée à la réduction des Yag",
                    "Total"],[
                        round(timeCataractSurgery * currentInterview.costs_resources.cataract_q) +
                        round(timeConsultations * currentInterview.costs_resources.consult_q),
                        data_chart2.reduce((p, o)=>p + o),
                        round(round(timeCataractSurgery * currentInterview.costs_resources.cataract_q) +
                        round(timeConsultations * currentInterview.costs_resources.consult_q) +
                        data_chart2.reduce((p, o)=>p + o))

                    ]

                ))

                round(timeCataractSurgery * currentInterview.costs_resources.cataract_q) +
                round(timeConsultations * currentInterview.costs_resources.consult_q)

                data_chart2.reduce((p, o)=>p + o)

            }

    }, [reductionOfNumOfYag])


    const print_rep_b = (str, txt, what='{0}') => {
        let split_str = str.split(what)
        if(Array.isArray(txt))
            txt.map((t,i)=>{ split_str.splice(i*2+1, 0, <b key={i} style={{fontSize:'1.5em'}}>{t}</b>)})
        else
            split_str.splice(1, 0, <b key="l1" style={{fontSize:'1.5em'}}>{txt}</b>)
        return split_str
    }

    if(!reductionOfNumOfYag)
        return (
            <div className="container-fluid">
                <img src="/assets/images/header_pdf.png" className="img-fluid mb-4" alt="Responsive image"></img>
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        )


    return (
        <div className="container-fluid pdf-render">
            <button className="btn btn-primary m-3" onClick={()=>{createPdf(pages.current)}}>Sauvegarder au format pdf</button>
            <PDFExport
                paperSize="A4"
                margin={'0.6cm'}
                scale={0.81}
                landscape={true}
                forcePageBreak=".page-break" ref={pages}>

            <section className="">
                <img src="/assets/images/header_pdf.png" className="img-fluid mb-4" alt="Responsive image"/>
            </section>

            <section className="page-break">
                <h2><b>1 - Répartition des LIO</b></h2>
                <div className="my-2">
                    <div id="current_future_pd" className="mx-auto" style={{width:'100%', height:'500px'}}></div>
                </div>
                <div className="d-flex justify-content-center my-2">
                    <p>Document établi dans le cadre d’une simulation d’impact budgétaire médico économique. A usage exclusif de son destinataire.
                    Les volumes sont donnés à titre indicatif pour les besoins de la simulation.
                    Les noms de marque sont la propriété exclusive des laboratoires et ne sont citées qu’à des fins de simulation de l’impact
                    budgétaire du laser Nd:YAG.</p>
                </div>

            </section>
            <section className="page-break">
                <h2><b>2 - Résultats</b></h2>
                <h4>a/ Nombre de procédures laser Nd:YAG et les complications associées</h4>
                <div className="row mt-4">
                    <div className="col">
                        <div id="reduction_number_yag" style={{width:'100%', height:'500px'}}></div>
                        <p>{print_rep_b(__('The total number of yag would be reduced by {0} per year,'),
                            round(reductionOfNumOfYag[0] - reductionOfNumOfYag[1]))}{' '}
                            {print_rep_b(__('a {0}% reduction in the number of YAG'),
                            round((round(reductionOfNumOfYag[0] - reductionOfNumOfYag[1]) / reductionOfNumOfYag[0])*100,1)+'%',
                            '{0}%')}</p>
                    </div>
                    <div className="col">
                        <div id="tot_compl_YAG_current_future" style={{width:'100%', height:'500px'}}></div>
                        <p>{print_rep_b(__('The total number of yag-related complications would be reduced by {0},'), round(
                                (Object.values(currentInterview.clinical_data).reduce((p, v)=>p + v * reductionOfNumOfYag[0], 0) -
                                Object.values(currentInterview.clinical_data).reduce((p,v)=>p + v * reductionOfNumOfYag[1], 0))/100))}{' '}
                            {print_rep_b(__('i.e. a {0}% reduction in complications'),round(
                                ((Object.values(currentInterview.clinical_data).reduce((p, v)=>p + v * reductionOfNumOfYag[0], 0) -
                                Object.values(currentInterview.clinical_data).reduce((p,v)=>p + v * reductionOfNumOfYag[1], 0))) /
                                Object.values(currentInterview.clinical_data).reduce((p, v)=>p + v * reductionOfNumOfYag[0], 0) *100 ,1)+'%','{0}%')}

                        </p>
                    </div>
                </div>

            </section>

            <section className="page-break">
            <h2><b>2 - Résultats</b></h2>
                <h4>b/ Impact budgétaire : point de vue de l’Assurance Maladie</h4>
                <div className="row mt-4">
                    <div className="col">
                        <div id="costs-scenarios" style={{width:'100%', height:'500px'}}></div>
                    </div>
                    <div className="col">
                        <div id="costs-scenarios2" style={{width:'100%', height:'500px'}}></div>
                    </div>
                </div>
                <h5>
                    {__('The overall savings for health insurance related to the reduction in the number of Yag are')}
                        : <b className="text-nowrap">{format_num(round(
                            ph_tot_compl_YAG_current_future[0].reduce((p,v)=>(p+v)) - ph_tot_compl_YAG_current_future[1].reduce((p,v)=>(p+v)),2))}€</b>
                </h5>
            </section>

            <section className="page-break">
            <h2><b>2 - Résultats</b></h2>
                <h4>c/ Temps gagné</h4>
                <div className="mt-4">
                        <div id="costs-scenarios3" style={{width:'100%', height:'500px'}}></div>
                    <br />
                    <p>
                    {print_rep_b(__('The total time saved by doing less YAG and having fewer complications to manage is: {0} hours'),
                                round(f_tot_efficient_current_future[0].reduce((p,v,i)=>(v-f_tot_efficient_current_future[1][i] + p),0),2))}
                    </p>
                </div>
            </section>

            <section className="page-break">
            <h2><b>2 - Résultats</b></h2>
                <h4>d/ Impact budgétaire : point de vue de l’établissement médical</h4>
                <div className="row mt-4">
                    <div className="col">
                        <div id="chart_1" style={{width:'100%', height:'500px'}}></div>
                    </div>
                    <div className="col">
                        <div id="chart_3" style={{width:'100%', height:'500px'}}></div>
                    </div>
                </div>
                <p>
                {print_rep_b(
                __("These additional procedures will generate a total additional income for the medical establishment of :{0}, an increase in turnover of {0}"),
                finanicial_data)}
                </p>
                {(currentInterview.typeconfig!='private')&&
                (<div className="row">
                    <div className="col">
                        <p> <img src="/assets/images/Alcon_RGB.png" width="100px" /> </p>
                    </div>
                </div>)}
            </section>


            {(currentInterview.typeconfig=='private')&&(
            <section className="page-break">
                <h2><b>2 - Résultats</b></h2>
                <h4>e/ Impact budgétaire : point de vue du chirurgien</h4>
                <div className="row">
                    <div className="col">
                        <div id="chart_1_pr" style={{width:'100%', height:'500px'}}></div>
                    </div>
                    <div className="col">
                        <div id="chart_3_pr" style={{width:'100%', height:'500px'}}></div>
                    </div>
                </div>
                <p>
                {!timeCataractSurgery?'':print_rep_b('Ces procédures supplémentaires généreraient un revenu additionnel total pour le chirurgien de: {0}',
                    format_num(round(round((timeCataractSurgery * currentInterview.costs_resources.cataract_q) +
                    (timeConsultations * currentInterview.costs_resources.consult_q) +
                    data_chart2.reduce((p, o)=>p + o))))+'€')}
                </p>
                <div className="row">
                    <div className="col">
                        <p> <img src="/assets/images/Alcon_RGB.png" width="100px" /> </p>
                    </div>
                </div>
            </section>
            )}

            </PDFExport>
        </div>

    )
  }


  export default Result;



  const default_color = ['#00aeef', '#007483','#e5d4b7','#9bbb59','#dedfe6','#d0dbe5','#01ad44','#713f94']

  const barChartOpt_current_future = (category, actual, future)=>({

      tooltip: {},
      legend: {},
      xAxis: {
          data: category
      },
      yAxis: {},
      series: [{
          name: __('Current'),
          type: 'bar',
          itemStyle:{
              color: default_color[0]
          },
          label:{
              show: true,
              rotate: 0,
              fontSize:14,
              color:'#FFF'
          },
          data: actual
      }, {
          name: __('Future'),
          type: 'bar',
          itemStyle:{
              color: default_color[1]
          },
          label:{
              show: true,
              rotate: 0,
              fontSize:14,
              color:'#FFF'
          },
          data: future
      }]
  })

  const barChartOpt = (category, data, yname, legend)=>({

    tooltip: {},
    legend:{show:legend},
    xAxis: [
        {data: category}
    ],
    yAxis: [
        {
            name: yname,
            type: 'value',
            nameLocation: 'center',
            nameGap:20,
            max: max(data.map((a)=>a?a.value:0)) + 1
        }
    ],
    series: [
        ...data.map((o={}, i)=>(
        {
            name: o.name,
            type: 'bar',
            itemStyle:{
                color:  default_color[i]
            },
            label:{
                show: true,
                rotate: 0,
                fontSize:14,
                color:'#FFF'
            },
            data: o.value
        }))
    ]
})


  const barChartOpt_c_f_m = (category, actual, middle, future)=>({

      tooltip: {},
      legend: {},
      xAxis: {
          data: category,
           axisLabel:{
            interval: 0,
            fontSize:9,
            formatter: (v) => (v.split(' ').map((v,i) =>i%2?v+"\n":v ).join(' '))
        }
      },
      yAxis: {},
      series: [{
          name: __('Current'),
          type: 'bar',
          itemStyle:{
              color: default_color[0]
          },
          label:{
              show: true,
              rotate: 0,
              fontSize:14,
              color:'#FFF'
          },
          data: actual
      }, {
          type: 'scatter',
          symbolOffset : [0, '-100%'],
          symbolSize : 50,
          data: middle.map((o)=>max(o)),
          itemStyle:{ color: '#b3c82b' },
          label: {
              show: true,
              fontSize: 14,
              color: '#333',
              formatter: (obj) =>(round(middle[obj.dataIndex][0] - middle[obj.dataIndex][1]))
          }
      }, {
          name: __('Future'),
          type: 'bar',
          itemStyle:{
              color: default_color[1]
          },
          label:{
              show: true,
              rotate: 0,
              fontSize:14,
              color:'#FFF'
          },
          data: future
      }]
  })



  const ph_barChartOpt_scenarios2 = (category, data)=>({

    tooltip: {},
    legend:{
        icon: 'none'
    },
    xAxis: {
        data: category,
        silent: false,
        splitLine: {
            show: false
        },
        splitArea: {
            show: false
        },
        axisLabel:{
            interval: 0,
            fontSize:9,
            formatter: (v) => (v.split(' ').map((v,i) =>i%2?v+"\n":v ).join(' '))
        }
    },
    yAxis: {},
    series: [
        {
            name: __('Cost (-)/ Savings (+)'),
            type: 'bar',
            itemStyle:{
                color: ({dataIndex})=> ((dataIndex==6)? [default_color[3]] : [default_color[0]])
            },
            tooltip: {
                formatter: '{a}<br />{b0}: {c0}€'
            },
            label:{
                show: true,
                rotate: 0,
                width: 10,
                color:'#333',
                position : 'top',
                formatter: ({value}) => (format_num(value)+'€'),
                backgroundColor: '#fff8',
                padding: 5
            },
            data: data.map(v=>round(v))
        }
    ]

})

const ph_barChartOpt = (category, data)=>({

    tooltip: {},
    legend:{

    },
    xAxis: {
        data: category,
        silent: false,
        splitLine: {
            show: false
        },
        splitArea: {
            show: false
        },
        axisLabel:{
            interval: 0,
            formatter: (v) => (v.split(' ').map((v,i) =>i%2?v+"\n":v ).join(' ')),
            fontSize: 9
        }
    },
    yAxis: {},
    series: data.map((v, i)=>(
        {
            name: i==0?__('Current scenario'):__('future scenario'),
            type: 'bar',
            itemStyle:{
                color: default_color[i]
            },
            tooltip: {
                formatter: '{a}<br />{b0}: {c0}€'
            },
            label:{
                show: true,
                rotate: 0,
                color:'#333',
                position : 'top',
                formatter: ({value}) => (format_num(value)+'€'),
                backgroundColor: '#fff8',
                padding: 5
            },
            data: v.map(val=>round(val))
        }
    ))

})


const eff_barChartOpt = (category, data)=>({

    tooltip: {},
    legend:{

    },
    xAxis: {
        data: category,
        silent: false,
        splitLine: {
            show: false
        },
        splitArea: {
            show: false
        },
        axisLabel:{
            interval: 0,
            formatter: (v) => (v.split(' ').map((v,i) =>i%2?v+"\n":v ).join(' '))
        }
    },
    yAxis: { name: "Hours", nameLocation: 'middle'},
    series: (()=>{let o = data.map((v, i)=>(
        {
            name: i==0?__('Current scenario'):__('future scenario'),
            type: 'bar',
            itemStyle:{
                color: default_color[i]
            },
            tooltip: {

            },
            label:{
                show: true,
                rotate: 0,
                color:'#333',
                position : 'top',
                backgroundColor: '#fff8',
                padding: 5
            },
            data: v.map(val=>round(val,2))
        }
    )); o.splice(1,0,{
        type: 'scatter',
        symbolOffset : [0, '-100%'],
        symbolSize : 50,
        data: data[0].map((v,i)=> max(v, data[1][i])),
        itemStyle:{ color: '#b3c82b' },
        label: {
            show: true,
            fontSize: 14,
            color: '#333',
            formatter: (obj) =>(round(data[0][obj.dataIndex] - data[1][obj.dataIndex], 2))
        }
    });
    return o})()

})


const barChartOpt_eur = (category, data)=>({

    tooltip: { formatter: (o)=>(o.value+'€') },
    legend:{},
    xAxis: {
        data: category,
        axisLabel:{
            interval: 0,
            formatter: (v) => (v.split(' ').map((v,i) =>i%2?v+"\n":v ).join(' '))
        }
    },

    yAxis: { formatter: (o)=>(o.value+'€') },
    series: [{
            name: name,
            type: 'bar',
            itemStyle:{
                color: (o)=>default_color[o.dataIndex==data.length - 1?3:0]
            },
            label:{
                show: true,
                rotate: 0,
                fontSize:20,
                color:'#FFF',
                formatter: ({value}) => (format_num(value)+'€'),
            },
            data: data
        }]

})

const f_barChartOpt = (category, data)=>({

    tooltip: {},
    legend:{},
    xAxis: {
        data: category
    },
    yAxis: {},
    series: [{
            name: name,
            type: 'bar',
            itemStyle:{
                color: default_color[0]
            },
            label:{
                show: true,
                rotate: 0,
                fontSize:18,
                color:'#FFF'
            },
            data: data
        }]

})


const barChartOpt_c_f = (category, actual, future)=>({

    tooltip: {},
    legend:{
        selectMode: false
    },
    xAxis: {
        data: category,
        axisLabel: {
            rotate:-30,
            margin: 8,
            fontSize: 10,
        }
    },
    yAxis: {},
    series: [{
        name: __('Current'),
        type: 'bar',
        itemStyle:{
            color: '#00aeef'
        },
        label:{
            show: true,
            rotate: 90,
            fontSize:12,
            color:'#FFF'
        },
        data: actual
    }, {
        name: __('Future'),
        type: 'bar',
        itemStyle:{
            color: '#007483'
        },
        label:{
            show: true,
            rotate: 90,
            fontSize:12,
            color:'#FFF'
        },
        data: future
    }]
})
