import React, {useEffect, useState} from "react";
import { useSelector, useDispatch } from "react-redux";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


// components
import Login from './login'
import Dashboard from './dashboard'
import Interview from './interview'
import Result from './result'


const App = () => {

    const auth = useSelector(state => state.auth)
    const query = new URLSearchParams(location.search);
    const req_result = query.get('result')
    let errorFlag=0;
    let content
    document.body.classList.remove('login-page');
    try{
        if(req_result)
            content = (<Result id={req_result} />)
        else
            switch(auth.role){
                case 'admin':
                    content = (<Dashboard />)
                break;
                case 'commercial':
                    content = (<Interview />)
                break;
                default:
                    document.body.classList.add('login-page');
                    content = (<Login />)
            }


        return (
            <>
                {content}
                <ToastContainer />
            </>
        )
    } catch(error){
        console.log(error)
        if(!errorFlag){
            errorFlag+=1;
            location.reload()
        }
    }
}


export default App
