import React, {useEffect, useState, useRef, useMemo} from 'react';
import {round, log, exp, floor, ceil, max} from 'mathjs'

/** charts */
import echarts from 'echarts/lib/echarts'
// include bar chart
import 'echarts/lib/chart/bar'
import 'echarts/lib/chart/scatter'
// include tooltip and title component
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/title'
import 'echarts/lib/component/legend'
import {format_num} from './global_tool'

import __ from '../lang'



const DashboardGraph = ({currentInterview, reductionOfNumOfYag, timeCataractSurgery, timeConsultations,configType}) => {


    const [tabShow, setTabShow] = useState('chart_1')

            //E22                           // c_ndyag
    let O22 = reductionOfNumOfYag.reduce((p, v)=>(v * currentInterview.costs_resources.laser - p),0)

    let O23 = reductionOfNumOfYag.reduce((p, v)=>{
        return ((v * currentInterview.clinical_data.vitrectomy * currentInterview.costs_resources.vitrectomy / 100) - p)
    },0)
    let O26 = Object.keys(currentInterview.clinical_data).reduce((p, k)=>(
        reductionOfNumOfYag[1] * currentInterview.clinical_data[k] * currentInterview.costs_resources[k] / 100 + p
    ),0) - Object.keys(currentInterview.clinical_data).reduce((p, k)=>(
        reductionOfNumOfYag[0] * currentInterview.clinical_data[k] * currentInterview.costs_resources[k] / 100 + p
    ),0) //treatment_complic_yag
                // E22                      v_ndyag                                     c_visit
    let O63 = reductionOfNumOfYag.reduce((p, ry)=>(
        ry * currentInterview.costs_resources.laser_num * currentInterview.costs_resources.cost_consult +
        Object.keys(currentInterview.clinical_data).reduce((pp, k)=>(
            currentInterview.clinical_data[k] * ry * currentInterview.costs_resources.cost_consult *
            currentInterview.costs_resources[`${k}_num`]  / 100 + pp), 0) - p
    ), 0) // consult_visits_costs

    let prods_cost = currentInterview.custom_avg.list.reduce((p, obj)=>{
        let act, fut
        if(currentInterview.type=='number'){
            act = obj.actual_avg.number
            fut = obj.future_avg.number
        }else{
            act = parseFloat(obj.actual_avg.pr_usage)?(parseInt(currentInterview.custom_avg.actual_cataract) * (parseFloat(obj.actual_avg.pr_usage) / 100)):0
            fut = parseFloat(obj.future_avg.pr_usage)?(parseInt(currentInterview.custom_avg.actual_cataract) * (parseFloat(obj.future_avg.pr_usage) / 100)):0
        }
        return (
            p + (obj.future_avg.price * fut) - (obj.actual_avg.price * act)
        )}, 0)


    let chart_3, chart_1, chart_2


    useEffect(()=>{
        if(tabShow == 'chart_3'){

            chart_3 = echarts.init(document.getElementById('chart_3'))
            chart_3.setOption(barChartOpt_eur(
                        [
                            __("Income from additional procedures"),
                            configType=='private' ? 'Chirurgie de la rètine' : __("YAG income + associated complications"),
                            __("Difference IOL costs"),__("Total") ],
                        [
                            timeCataractSurgery * currentInterview.costs_resources.cataract +
                            (configType=='private'?0:timeConsultations * currentInterview.costs_resources.cost_consult),
                            configType=='private'?(round(O23)):round(O22 + O26 + O63),
                            -prods_cost,
                            round(timeCataractSurgery * currentInterview.costs_resources.cataract +
                                (configType=='private'?0:timeConsultations * currentInterview.costs_resources.cost_consult)+
                            (configType=='private'?(round(O23)):round(O22 + O26 + O63)) - prods_cost)
                        ]))
        }

        if(tabShow == 'chart_2'){
            chart_2 = echarts.init(document.getElementById('chart_2'))
            chart_2.setOption(barChartOpt_eur(
                [__('Cataract Surgery'), __('Consultations'), __('Total')],
                [
                    timeCataractSurgery * parseFloat(currentInterview.costs_resources.cataract),
                    timeConsultations * currentInterview.costs_resources.cost_consult,
                    timeCataractSurgery * parseFloat(currentInterview.costs_resources.cataract) +
                    timeConsultations * currentInterview.costs_resources.cost_consult
                ]
            ))
        }

        if(tabShow == 'chart_1'){
            chart_1 = echarts.init(document.getElementById('chart_1'))
            chart_1.setOption(barChartOpt(
                [__('Cataract Surgery'), __('Consultations') ],
                [round(timeCataractSurgery), round(timeConsultations)]
            ))
        }

    }, [tabShow])

    const print_rep_b = (str, txt, what='{0}') => {
        let split_str = str.split(what)
        if(Array.isArray(txt))
            txt.map((t,i)=>{ split_str.splice(i*2+1, 0, <b key={i} style={{fontSize:'1.5em'}}>{t}</b>)})
        else
            split_str.splice(1, 0, <b key="l1" style={{fontSize:'1.5em'}}>{txt}</b>)
        return split_str
    }

    let total_actual_custom_avg = currentInterview.custom_avg.list.reduce((p,{actual_avg})=>(
        p + (currentInterview.type=='number'?parseInt(actual_avg.number):(actual_avg.pr_usage * currentInterview.custom_avg.actual_cataract/100))),0)

    let total_future_custom_avg = currentInterview.custom_avg.list.reduce((p,{future_avg})=>(
        p + (currentInterview.type=='number'?parseInt(future_avg.number):(future_avg.pr_usage * currentInterview.custom_avg.future_cataract/100))),0)


    return (
        <div className="content mb-4" style={{width:'97vw'}}>
            <h1 className="mb-3">{tabShow == 'chart_3'&&configType=='private' ? 'Financier – Perspective de la clinique' : __('Financial')}</h1>
                <div className="row">
                    {tabShow == 'chart_1'&&(
                    <div className="col-12" >
                        <h4 className="mb-3">
                            {__("I choose how I allocate the time saved into additional procedures (%):")} <br />
                            <i>{__('Cataract Surgery')}</i>: <b>{currentInterview.splitTime.cataract}%</b> {' - '}
                            <i>{__('Consultation')}</i>: <b>{currentInterview.splitTime.consult}%</b>
                        </h4>
                        <h5>{__('Number of additional acts')}</h5>

                        <div className="row">
                            <div className="col-8">
                                <div id="chart_1" style={{width:'100%', height:'60vh'}}></div>
                            </div>
                            <div className="col-4 d-flex align-items-center flex-column">

                                <button className="btn" data-toggle="collapse" data-target="#p1_info"><IconInfo /></button>
                                <div className="collapse" id="p1_info">
                                    <div className="card mt-4 px-4">

                                        <table className="table table-sm">
                                            <thead><tr><th className="text-right" colSpan={2}>{__('Time (minutes)')}</th></tr></thead>
                                            <tbody>
                                                <tr>
                                                    <td>{__('Duration of a consultation')}</td>
                                                    <td className="border border-primary">
                                                        {currentInterview.costs_resources.duration_consultion}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>{__('Duration of a Nd: YAG laser')}</td>
                                                    <td className="border border-primary">
                                                        {currentInterview.costs_resources.duration_yag_laser}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>{__('Duration of cataract surgery')}</td>
                                                    <td className="border border-primary">
                                                        {currentInterview.costs_resources.duration_cataract}
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>)}

                    {(tabShow == 'chart_2')&&(
                    <div className="col-12 " >
                        <h5>{__('Number of additional procedures')}</h5>
                        <div className="row">
                            <div className="col-8">
                                <div id="chart_2" style={{width:'100%', height:'60vh'}}></div>
                            </div>
                            <div className="col-4 d-flex align-items-center flex-column">

                                <button className="btn" data-toggle="collapse" data-target="#p1_info"><IconInfo /></button>
                                <div className="collapse" id="p1_info">
                                    <div className="card mt-4 px-4">

                                        <table className="table">
                                            <thead><tr><th className="text-right" colSpan={2}>{__('Revenue')}</th></tr></thead>
                                            <tbody>
                                                <tr>
                                                    <td>{__('Cataract Surgery')}</td>
                                                    <td className="border border-primary">
                                                        {currentInterview.costs_resources.cataract} €
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>{__('Consultations')}</td>
                                                    <td className="border border-primary">
                                                        {currentInterview.costs_resources.cost_consult} €
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>)}
                        {(tabShow == 'chart_3')&&(<div className="col-12 " >
                        <h5>{__('Summary of revenues between additional procedures and avoided procedures + difference in IOL costs')}</h5>

                        <div className="row">
                            <div className="col-8">
                                <div id="chart_3" style={{width:'100%', height:'60vh'}}></div>
                            </div>
                            <div className="col-4 d-flex align-items-center">

                                <h4>
                                {print_rep_b(
                                    __("These additional procedures will generate a total additional income for the medical establishment of :{0}, an increase in turnover of {0}"),
                                    [ format_num( round(timeCataractSurgery * currentInterview.costs_resources.cataract +
                                        (configType=='private'?0:timeConsultations * currentInterview.costs_resources.cost_consult)+
                                        (configType=='private'?(round(O23)):round(O22 + O26 + O63)) - prods_cost))+'€',

                                        round((timeCataractSurgery * currentInterview.costs_resources.cataract +
                                            (configType=='private'?0:timeConsultations * currentInterview.costs_resources.cost_consult)+
                                            (configType=='private'?(round(O23)):round(O22 + O26 + O63)) - prods_cost) /
                                                (currentInterview.costs_resources.cataract * total_actual_custom_avg) *100
                                        ,2)+'%'])}
                                </h4>
                            </div>
                        </div></div>)}

            </div>

            {tabShow!='chart_1'&&
                (<button
                    className="position-fixed btn btn-link"
                    onClick={()=>{setTabShow(configType=='private'?'chart_1':(tabShow=='chart_2'?'chart_1':'chart_2'))}}
                    style={{top:'10.2%',right:'4%'}}><IconPrev /></button>)}

            {tabShow!='chart_3'&&
                (<button
                    className="position-fixed btn btn-link"
                    onClick={()=>{setTabShow(configType=='private'?'chart_3':(tabShow=='chart_2'?'chart_3':'chart_2'))}}
                    style={{top:'70%',right:'2%'}}><IconArrow /></button>)}


        </div>
    )
}

export default DashboardGraph


const default_color = ['#00aeef', '#007483','#e5d4b7','#9bbb59','#9bbb59','#ff0000','#01ad44','#713f94']

const barChartOpt_eur = (category, data)=>({

    tooltip: { formatter: (o)=>(o.value+'€') },
    legend:{},
    xAxis: {
        data: category,
        axisLabel:{
            interval: 0,
            formatter: (v) => (v.split(' ').map((v,i) =>i%2?v+"\n":v ).join(' '))
        }
    },

    yAxis: { formatter: (o)=>(o.value+'€') },
    series: [{
            name: name,
            type: 'bar',
            itemStyle:{
                color: (o)=>default_color[o.dataIndex==data.length - 1?3:0]
            },
            label:{
                show: true,
                rotate: 0,
                fontSize:20,
                color:'#FFF',
                formatter: (o)=>(format_num(o.value)+'€')
            },
            data: data
        }]

})

const barChartOpt = (category, data)=>({

    tooltip: {},
    legend:{},
    xAxis: {
        data: category
    },
    yAxis: {},
    series: [{
            name: name,
            type: 'bar',
            itemStyle:{
                color: default_color[0]
            },
            label:{
                show: true,
                rotate: 0,
                fontSize:18,
                color:'#FFF'
            },
            data: data
        }]

})



const IconInfo = () => (
    <svg width="3em" height="3em" className="svg-inline--fa text-primary" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path>
    </svg>
)

const IconPlus = () => (
    <svg width="2.5em" height="2.5em" className="svg-inline--fa text-primary" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
        <path fill="currentColor" d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path>
    </svg>
)

const IconPrev = () => (
    <svg  width="2.2em" height="2.2em" className="svg-inline--fa fa-angle-double-left fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
        <path fill="currentColor" d="M223.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L319.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L393.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34zm-192 34l136 136c9.4 9.4 24.6 9.4 33.9 0l22.6-22.6c9.4-9.4 9.4-24.6 0-33.9L127.9 256l96.4-96.4c9.4-9.4 9.4-24.6 0-33.9L201.7 103c-9.4-9.4-24.6-9.4-33.9 0l-136 136c-9.5 9.4-9.5 24.6-.1 34z"></path>
    </svg>
)

const IconArrow = () => (
    <img src="/assets/images/next.png" style={{width:'5em'}} />

)
