import { combineReducers } from "redux";
import {reducer as offlineReducer} from 'redux-offline-queue'

import configurations from './configurations';
import products from './products';
import auth from './auth';
import users from './users.jsx';
import interviews from './interviews';
import historystudy from './history_study'
import result from './result'

const rootReducer = combineReducers({
    offline: offlineReducer,
    configurations,
    products,
    auth,
    users,
    interviews,
    historystudy,
    result
});

export default rootReducer;
