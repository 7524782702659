import React, {useEffect, useState, useMemo, useRef} from 'react';
import { useSelector, useDispatch } from "react-redux";
import DataTable from 'react-data-table-component';
import {fetch_users, delete_user, update_user} from '../actions'

const __ = txt => (txt)


const Users = () => {

    const [showModal, setShowModal] = useState(false)
    const[showModalPw, setShowModalPw] = useState(false)
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [filterText, setFilterText] = useState('');

    const dispatch = useDispatch();

    const users = useSelector(state => state.users.list)

    const filteredUser = users.filter(({name,email})=>(
        name && email && (name.toLowerCase().includes(filterText.toLowerCase()) || email.toLowerCase().includes(filterText.toLowerCase()))))




    useEffect(()=>{
        dispatch(fetch_users())
    },[dispatch])

    // implement functions

    const fnDelete = row => {
        if(confirm(__('delete selected user?') + `[ ${row.name} ]`))
            dispatch(delete_user(row.id))
    }

    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
          if (filterText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterText('');
          }
        };

        return <FilterComponent
                    onFilter={e => setFilterText(e.target.value)}
                    onClear={handleClear}
                    filterText={filterText}
                    openModal={()=>setShowModal(true)} />;
    }, [filterText, resetPaginationToggle]);


    //  table columns
    const columns = [
        {
          name: __('Name'),
          selector: 'name',
          sortable: true,
        }, {
          name: 'E-mail',
          selector: 'email',
          sortable: true,
        }, {
            name: __('Role'),
            selector: 'role',
            sortable: true,
        }, {
          name: '',
          selector: 'action',
          cell: row => (<>
          <button className="btn btn-link" onClick={()=>{setShowModal(row)}}><IconPencil /></button>
          <button className="btn btn-link" onClick={()=>{setShowModalPw(row)}}><IconAsterisk /></button>
          <button className="btn btn-link" onClick={()=>{fnDelete(row)}}><IconTrash /></button>
          </>)
        }
      ];

    return (
        <>
        <div className="card">
            <DataTable
                title={__('Users')}
                columns={columns}
                data={filteredUser}
                defaultSortField="role"
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                paginationsetShowModalPw={true}
            />
        </div>
        {showModal&&(<UserModal setShowModal={setShowModal} data={showModal} />)}
        {showModalPw&&(<ChangePasswordModal setShowModalPw={setShowModalPw} data={showModalPw} />)}
        </>
    )
}

export default Users

const ChangePasswordModal = ({setShowModalPw, data}) =>  {

    const modal = useRef()
    const formData = useRef()

    const dispatch = useDispatch();

    useEffect(()=>{
        setTimeout(()=>{modal.current.classList.add('show')},100)
    },[])

    const changePassword = e => {
        e.preventDefault()
        dispatch(update_user({password:formData.current.elements[0].value, id:data.id}))
        setShowModalPw(false)
        return false
    }

    return(
        <>
        <div className="modal fade pt-4" ref={modal} tabIndex="-1" role="dialog" aria-hidden="true" style={{display:'block',width:'100%'}}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{__('Change Password User')}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>{setShowModalPw(false)}}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                    {__('Set new password for user:')} {data.name}
                    <form ref={formData} onSubmit={changePassword} action="#" id="form-user">
                        <div className="row mt-4">
                            <div className="col">{__('new password')}:</div>
                            <div className="col"><input name="password" className="form-control" required minLength={4} /></div>
                        </div>
                    </form>

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={()=>{setShowModalPw(false)}}>{__('Close')}</button>
                        <button type="submit" className="btn btn-primary" form="form-user">{__('Save changes')}</button>
                    </div>
                </div>
            </div>
        </div>
        <div className="modal-backdrop fade show"></div>
        </>
    )
}

const UserModal = ({setShowModal, data}) =>  {
    data = typeof data == "boolean" ? {role:'commercial'} : data
    const modal = useRef()
    const formData = useRef()
    const [role, setRole] = useState(data.role || 'commercial')

    const dispatch = useDispatch();

    useEffect(()=>{
        setTimeout(()=>{modal.current.classList.add('show')},100)
    },[])

    const savedUser = e => {
        e.preventDefault()
        let dataForm = Array.from(formData.current.elements).reduce((u, formInput)=>(
            !formInput.name ? u : {...u, ...{[formInput.name]: formInput.value}}
            ),{role: role})
        dispatch(update_user(dataForm))
        setShowModal(false)
        return false
    }

    return (
        <>
        <div className="modal fade pt-4" ref={modal} tabIndex="-1" role="dialog" aria-hidden="true" style={{display:'block',width:'100%'}}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{__('Edit User')}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>{setShowModal(false)}}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form ref={formData} onSubmit={savedUser} action="#" id="form-user">
                            {data.id&&<input type="hidden" name="id" value={data.id}/>}
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="user_name">{__('name')}</label>
                                    <input type="text" className="form-control" name="name" id="user_name" defaultValue={data.name} required />
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="user_email">e-mail</label>
                                    <input type="email" className="form-control" name="email" id="user_email" defaultValue={data.email} required />
                                </div>
                                {!data.name&&(<div className="form-group col-md-6">
                                    <label htmlFor="user_pw">{__('password')}</label>
                                    <input type="text" name="password" className="form-control" id="user_pw" defaultValue={data.name} required />
                                </div>)}
                                <div className="form-group col-md-6">
                                    <label htmlFor="user_email">{__('role')}</label><br />
                                    <div className="custom-control custom-radio custom-control-inline">
                                        <input type="radio" id="user_role" className="custom-control-input" value="admin"
                                            checked={role=='admin'} onChange={(e)=>{setRole(e.target.value)}} />
                                        <label className="custom-control-label" htmlFor="user_role">{__('administrator')}</label>
                                    </div>
                                    <div className="custom-control custom-radio custom-control-inline">
                                        <input type="radio" id="user_role_c" className="custom-control-input" value="commercial"
                                            checked={role=='commercial'} onChange={(e)=>{setRole(e.target.value)}} />
                                        <label className="custom-control-label" htmlFor="user_role_c">{__('sales')}</label>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={()=>{setShowModal(false)}}>{__('Close')}</button>
                        <button type="submit" className="btn btn-primary" form="form-user">{__('Save changes')}</button>
                    </div>
                </div>
            </div>
        </div>
        <div className="modal-backdrop fade show"></div>
        </>
    )
}


const FilterComponent = ({ filterText, onFilter, onClear, openModal }) => (
    <div className="row w-100 text-right">
        <div className="col-md-2 offset-md-7"><button onClick={openModal} className="btn btn-primary btn-sm">{__('New')}</button></div>
        <div className="col-md-3">
            <div className="input-group input-group-sm">
                <input type="text" className="form-control" placeholder={__("Filter By name or e-mail")} value={filterText} onChange={onFilter}/>
                <div className="input-group-append">
                    <button type="button" className="input-group-text btn" onClick={onClear}>X</button>
                </div>
            </div>
        </div>
    </div>

);


const IconPencil = () => (
    <svg className="bi bi-pencil" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" d="M11.293 1.293a1 1 0 0 1 1.414 0l2 2a1 1 0 0 1 0 1.414l-9 9a1 1 0 0 1-.39.242l-3 1a1 1 0 0 1-1.266-1.265l1-3a1 1 0 0 1 .242-.391l9-9zM12 2l2 2-9 9-3 1 1-3 9-9z"/>
        <path fillRule="evenodd" d="M12.146 6.354l-2.5-2.5.708-.708 2.5 2.5-.707.708zM3 10v.5a.5.5 0 0 0 .5.5H4v.5a.5.5 0 0 0 .5.5H5v.5a.5.5 0 0 0 .5.5H6v-1.5a.5.5 0 0 0-.5-.5H5v-.5a.5.5 0 0 0-.5-.5H3z"/>
    </svg>
)

const IconTrash = () => (
    <svg className="bi bi-trash" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
        <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
    </svg>
)

const IconAsterisk = () => (
    <svg className="bi bi-asterisk" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1z"/>
    </svg>
)
