import React, {useEffect, useState, useRef} from 'react';
import { useSelector, useDispatch } from "react-redux";

import {fetch_configurations,update_configurations} from '../actions'

const __ = txt => (txt)


const Configurations = () => {

    const dispatch = useDispatch();

    const configs = useSelector(state => state.configurations.list)

    useEffect(()=>{
        dispatch(fetch_configurations())
    },[dispatch])

    let timeoutSave

    const saveConfig = e => {
        const data = {name: e.target.name, value: e.target.value}
        if(timeoutSave) clearTimeout(timeoutSave)
        timeoutSave = setTimeout(()=>{
            dispatch(update_configurations(data))
        },500)

    }

    // implement functions

    const inputSetType = (name, val) =>{
        if(['string','number'].indexOf(typeof val) != -1)
            return(
                <div key={name} className="row">
                    <label className="col-sm-4 col-form-label">
                        {name.replace(/(\.)/g,' > ').replace(/(_)/g,' ')}
                    </label>
                    <div className="col-sm-8">
                        <div className="input-group mb-3">
                            <input type={typeof val} name={name} className="form-control" defaultValue={val} onChange={saveConfig} />
                            <div className="input-group-append">
                                <span className="input-group-text" id="basic-addon2">
                                    {name.indexOf('pr_')>-1?'%':name.indexOf('.cost')>-1?'€':'n'}
                                </span>
                            </div>
                        </div>

                    </div>
                </div>
            )


        if(typeof val == 'object')
            return Object.keys(val).map(subname =>(
                inputSetType(`${name}.${subname}`, val[subname])
            ))
    }

    return (
        <div className="card">
            <div className="card-header bg-white"><h3>{__('Constants')}</h3></div>
            <div className="card-body">
                <form action="#">
                    <div className="form-group">
                        {configs.map(({name,value})=>(
                            inputSetType(name, value)
                        ))}

                    </div>
                </form>
            </div>

        </div>

    )
}

export default Configurations

