import {RESULT_LOAD} from '../actions'


export default (state = {}, action) => {

    switch (action.type) {
        case RESULT_LOAD: {
            return action.payload
        }

        default: {
            return state;
        }
    }
};
