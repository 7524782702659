import {CONFIGURATION_FETCH_ERROR, CONFIGURATION_STORED, CONFIGURATION_UPDATE, CHANGE_TYPE_CONFIG} from '../actions'


const initialState = {
    list: [],
    type: 'public',
    error: ''

};

export default (state = initialState, action) => {

    switch (action.type) {
        case CONFIGURATION_STORED: {
            return {
                ...state,
                ...{list: action.payload},
                ...{error: ''}
            };
        }

        case CONFIGURATION_UPDATE: {
            return {...state, list: state.list.map(conf => (
                conf.name==action.payload.name ? {...conf, value: action.payload.value} : conf
            ))}
        }

        case CONFIGURATION_FETCH_ERROR: {
            return {...state, ...{error: action.payload}}
        }

        case CHANGE_TYPE_CONFIG: {
            return {...state, type: action.payload?'private':'public'}
        }

        default: {
            return state;
        }
    }
};
