import React, {useEffect, useState, useRef, useMemo} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {round} from 'mathjs'

import { update_inteview } from '../actions'

import __ from '../lang'


const StepClinicalData = ({currentInterview}) => {

    const dispatch = useDispatch()
    const constants = useSelector(state=>state.configurations)
    let default_prob_yag = constants.list.find(({name})=>name=='default_prob_laser_yag').value

    const vitrectomy = useRef()
    const glaucoma = useRef()
    const omc = useRef()

    const products = useSelector(({products})=>products.list).filter(({_id})=>currentInterview.selectedProd.includes(_id))
    const form = useRef()
    currentInterview = {...currentInterview}
    if(!currentInterview.clinical_data) currentInterview.clinical_data = {}

    /** temp data */


    const changeStep = (step, e) => {
        if(e){
            e.preventDefault();
            e.stopPropagation();
        }

        form.current.classList.add('was-validated')
        if(form.current.checkValidity()==false) return
        dispatch(update_inteview({ step: currentInterview.step + step}))

    }

    useEffect(()=>{

        return(()=>{
            dispatch(update_inteview({ clinical_data: Array.from(form.current.elements).reduce((p,{name, value})=>(name?{...p,[name]:value}:p),{})}))
        })
    }, [])



    return (
        <>
        <div className="content" style={{width:'85vw'}}>
            <form className="needs-validation" noValidate id="step-clinic-data" ref={form} onSubmit={e=>{changeStep(1,e)}}>
                <div className="row">
                    <div className="col-5 row">
                        <h5 className="col-md-12">{__('Probability of complications linked to YAG')}:</h5>
                        <div className="col-md-4"></div>
                        <div className="col-md-8 text-right"><b>{__('Probability')}</b></div>
                        <div className="col-md-6">{__('Vitrectomy')}:</div>
                        <div className="col-md-6">
                            <div className="input-group input-group-sm">
                                <input type="number" step="0.01" name="vitrectomy" placeholder=".." required className="form-control"
                                    defaultValue={currentInterview.clinical_data.vitrectomy || default_prob_yag.vitrectomy} ref={vitrectomy} />
                                <div className="input-group-append"><span className="input-group-text">%</span></div>
                                <div className="input-group-append">
                                    <button className="btn btn-secondary" type="button" onClick={()=>{
                                        vitrectomy.current.value = default_prob_yag.vitrectomy}} title="reset">
                                            <IconUndo /></button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">{__('Glaucoma')}:</div>
                        <div className="col-md-6">
                            <div className="input-group input-group-sm">
                                <input type="number" step="any" name="glaucoma" placeholder=".." required className="form-control"
                                    defaultValue={currentInterview.clinical_data.glaucoma || default_prob_yag.glaucoma} ref={glaucoma} />

                                <div className="input-group-append">
                                    <span className="input-group-text">%</span>
                                </div>
                                <div className="input-group-append">
                                    <button className="btn btn-secondary" type="button" onClick={()=>{
                                        glaucoma.current.value = default_prob_yag.glaucoma}} title="reset">
                                            <IconUndo /></button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">{__('Cystoid macular edema (OMC)')}:</div>
                        <div className="col-md-6">
                            <div className="input-group input-group-sm">
                                <input type="number" step="any" name="omc" placeholder=".." required className="form-control"
                                    defaultValue={currentInterview.clinical_data.omc || default_prob_yag.OMC} ref={omc} />

                                <div className="input-group-append"><span className="input-group-text">%</span></div>
                                <div className="input-group-append">
                                    <button className="btn btn-secondary" type="button" onClick={()=>{
                                        omc.current.value = default_prob_yag.OMC}} title="reset">
                                            <IconUndo /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col ">
                        <h5>{__('Rate of YAG laser according to the IOLs brand')}</h5>
                        <ul className="list-group list-group-flush overflow-hidden">
                            <li className="list-group-item">
                                <div className="row">
                                    <div className="col-md-4"></div>
                                    <div className="col-md-4">
                                        <b>{__('{0} years rate')
                                            .replace('{0}', constants.list.find(({name})=>name=='clinical_year_by').value)}</b>
                                    </div>
                                    <div className="col-md-4"><b>{__('Source')}</b></div>
                                </div>
                            </li>
                            {products.map(({name,_id, data_source})=>(
                                <li className="list-group-item row" key={_id} >
                                    <div className="row">
                                        <div className="col-md-4">{name}</div>
                                        <div className="col-md-4">{round(data_source.yag_capsulotomy/data_source.total_eyes*100, 1)}%</div>
                                        <div className="col-md-4">{data_source.study_name}</div>
                                    </div>
                                </li>
                            ))}
                        </ul>


                    </div>
                </div>
            </form>
        </div>

        <div className="position-fixed p-1 text-right" style={{right:0,top:'50%'}}>

            <button type="submit" className="btn btn-primary" onClick={()=>{changeStep(1)}}>{__('next')+' >'} </button>
        </div>
        </>
    )
}

export default StepClinicalData


const IconUndo = () => (
    <svg  width="1em" height="1em" className="svg-inline--fa fa-undo fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path fill="currentColor" d="M212.333 224.333H12c-6.627 0-12-5.373-12-12V12C0 5.373 5.373 0 12 0h48c6.627 0 12 5.373 12 12v78.112C117.773 39.279 184.26 7.47 258.175 8.007c136.906.994 246.448 111.623 246.157 248.532C504.041 393.258 393.12 504 256.333 504c-64.089 0-122.496-24.313-166.51-64.215-5.099-4.622-5.334-12.554-.467-17.42l33.967-33.967c4.474-4.474 11.662-4.717 16.401-.525C170.76 415.336 211.58 432 256.333 432c97.268 0 176-78.716 176-176 0-97.267-78.716-176-176-176-58.496 0-110.28 28.476-142.274 72.333h98.274c6.627 0 12 5.373 12 12v48c0 6.627-5.373 12-12 12z"></path>
    </svg>
)
