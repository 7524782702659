import React, {useEffect, useState, useRef, useMemo} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { update_inteview } from '../actions'
import __ from '../lang'


const StepCost = ({currentInterview, configType}) => {

    const dispatch = useDispatch()

    //if(!currentInterview.costs_resources) currentInterview.costs_resources = {}

    const [splitTime, setSplitTime] = useState( currentInterview.splitTime || {cataract: 30, consult: 70})
    const direct_costs = useSelector(state=>state.configurations).list.find(({name})=>name=='direct_cost').value
    const direct_costs_p = (useSelector(state=>state.configurations).list.find(({name})=>name=='direct_cost_private')||{}).value
    const form = useRef()
    //  parse data from configurations
    currentInterview = {...{
        costs_resources: {
          laser: (configType=='private'?direct_costs_p:direct_costs).ndyag.cost,
          laser_num: direct_costs.ndyag.visits,
          cataract: (configType=='private'?direct_costs_p:direct_costs).cataract.cost,
          vitrectomy: (configType=='private'?direct_costs_p:direct_costs).vitrectomy.cost,
          vitrectomy_num: direct_costs.vitrectomy.visits,
          glaucoma: (configType=='private'?direct_costs_p:direct_costs).glaucoma.cost,
          glaucoma_num: direct_costs.glaucoma.visits,
          omc: (configType=='private'?direct_costs_p:direct_costs).OMC.cost,
          omc_num: direct_costs.OMC.visits,
          cost_consult: (configType=='private'?direct_costs_p:direct_costs).consultant.cost,
          duration_consultion: direct_costs.consultant.time,
          duration_yag_laser: direct_costs.antiYAG.time,
          duration_cataract: direct_costs.cataract.time
        }}, ...currentInterview
    }

    const changeStep = (step, e) => {
        if(e){
            e.preventDefault();
            e.stopPropagation();
        }

        form.current.classList.add('was-validated')
        if(form.current.checkValidity()==false) return
        dispatch(update_inteview({step: currentInterview.step + step}))
    }

    const setSplitTimeCheck = (name, value) => {
        const name2 = name == 'cataract' ? 'consult' : 'cataract'
        value = Math.min(100,Math.max(0,value))
        setSplitTime({[name]: value, [name2]: (100 - value)})
        dispatch(update_inteview({splitTime:{[name]: value, [name2]: (100 - value)}}))
    }

    const saveStep = ()=>{
        let costs_resources = Array.from(form.current.elements).reduce((p,{name, value})=>(!name?p:{...p,[name]:value}),{})
        dispatch(update_inteview({costs_resources}))
    }

    useEffect(()=>{
        dispatch(update_inteview({splitTime}))
        return saveStep
    }, [])
    const columns_div =  'input-group-sm col-md-'+(configType=='public' ? '4': '3')
    return (
        <>
        <div className="content w-75 pt-5 mb-5">
            <form className="needs-validation" noValidate id="step-cost" ref={form} onSubmit={e=>{changeStep(1,e)}}>

                <div className="row">
                    <h5 className="col-md-12 mb-4">{__('Entering resources for care secondary to cataract surgery and associated costs')}:</h5>
                    <div className={configType=='public'?"col-md-4 offset-md-4":"col-md-3 offset-md-3"}><b>{__('Cost / procedure')}</b></div>
                    {configType=='private'&&(<div className="col-md-3 text-capitalize"><b>{__('quotation')}</b></div>)}
                    <div className={columns_div}><b>{__('Number of visits')}</b></div>
                    {/* laser */}
                    <div className={columns_div}>{__('Laser Nd.YAG')}:</div>
                    <div className={columns_div}>
                        <div className="input-group input-group-sm mb-2">
                            <input type="number" step="any" name="laser" placeholder=".." required className="form-control"
                                readOnly={true}
                                defaultValue={currentInterview.costs_resources.laser}/>
                            <div className="input-group-append"><span className="input-group-text">€</span></div>
                        </div>
                    </div>
                    {configType=='private'&&( <div className={columns_div}>
                        <div className="input-group input-group-sm mb-2">
                            <input type="number" step="any" name="laser_q" placeholder=".." className="form-control"
                                defaultValue={currentInterview.costs_resources.laser_q||direct_costs_p.laser_co.cost}/>
                            <div className="input-group-append"><span className="input-group-text">€</span></div>
                        </div>
                    </div>)}
                    <div className={columns_div}>
                        <input type="number" name="laser_num" required className="form-control"
                        defaultValue={currentInterview.costs_resources.laser_num || 1}/>
                    </div>
                    {/* cataract */}
                    <div className={columns_div}>{__('Cataract surgery')}:</div>
                    <div className={columns_div}>
                        <div className="input-group input-group-sm mb-2">
                            <input type="number" step="any" name="cataract" placeholder=".." required className="form-control"
                                readOnly={true}
                                defaultValue={currentInterview.costs_resources.cataract}/>
                            <div className="input-group-append"><span className="input-group-text">€</span></div>
                        </div>
                    </div>
                    {configType=='private'&&( <div className={columns_div}>
                        <div className="input-group input-group-sm mb-2">
                            <input type="number" step="any" name="cataract_q" placeholder=".." className="form-control"
                                defaultValue={currentInterview.costs_resources.cataract_q||direct_costs_p.cataract_co.cost}/>
                            <div className="input-group-append"><span className="input-group-text">€</span></div>
                        </div>
                    </div>)}
                    <div className={columns_div}></div>
                    {/* vitrectomy */}
                    <div className={columns_div}>{__('Vitrectomy')}:</div>
                    <div className={columns_div}>
                        <div className="input-group input-group-sm mb-2">
                            <input type="number" step="any" name="vitrectomy" placeholder=".." required className="form-control"
                                readOnly={true}
                                defaultValue={currentInterview.costs_resources.vitrectomy}/>
                            <div className="input-group-append"><span className="input-group-text">€</span></div>
                        </div>
                    </div>
                    {configType=='private'&&( <div className={columns_div}>
                        <div className="input-group input-group-sm mb-2">
                            <input type="number" step="any" name="vitrectomy_q" placeholder=".." className="form-control"
                                defaultValue={currentInterview.costs_resources.vitrectomy_q||direct_costs_p.vitrectomy_co.cost}/>
                            <div className="input-group-append"><span className="input-group-text">€</span></div>
                        </div>
                    </div>)}
                    <div className={columns_div}>
                        <input type="number" name="vitrectomy_num" required className="form-control"
                            defaultValue={currentInterview.costs_resources.vitrectomy_num || 2}/>
                    </div>
                    {/* glaucoma */}
                    <div className={columns_div}>{__('Treatment of glaucoma')}:</div>
                    <div className={columns_div}>
                        <div className="input-group input-group-sm mb-2">
                            <input type="number" step="any" name="glaucoma" placeholder=".." required className="form-control"
                                readOnly={true}
                                defaultValue={currentInterview.costs_resources.glaucoma}/>
                            <div className="input-group-append"><span className="input-group-text">€</span></div>
                        </div>
                    </div>
                    {configType=='private'&&( <div className={columns_div}>
                        <div className="input-group input-group-sm mb-2">
                            <input type="number" step="any" name="glaucoma_q" placeholder=".." className="form-control"
                                defaultValue={currentInterview.costs_resources.glaucoma_q||direct_costs_p.glaucoma_co.cost}/>
                            <div className="input-group-append"><span className="input-group-text">€</span></div>
                        </div>
                    </div>)}
                    <div className={columns_div}>
                        <input type="number" name="glaucoma_num" required className="form-control"
                            defaultValue={currentInterview.costs_resources.glaucoma_num || 2}/>
                    </div>
                    {/* omc */}
                    <div className={columns_div}>{__('OMC treatment')}:</div>
                    <div className={columns_div}>
                        <div className="input-group input-group-sm mb-2">
                            <input type="number" step="any" name="omc" placeholder=".." required className="form-control"
                                readOnly={true}
                                defaultValue={currentInterview.costs_resources.omc}/>
                            <div className="input-group-append"><span className="input-group-text">€</span></div>
                        </div>
                    </div>
                    {configType=='private'&&( <div className={columns_div}>
                        <div className="input-group input-group-sm mb-2">
                            <input type="number" step="any" name="omc_q" placeholder=".." className="form-control"
                                defaultValue={currentInterview.costs_resources.omc_q || direct_costs_p.omc_co.cost}/>

                            <div className="input-group-append"><span className="input-group-text">€</span></div>
                        </div>
                    </div>)}
                    <div className={columns_div}>
                        <input type="number" name="omc_num" required className="form-control"
                            defaultValue={currentInterview.costs_resources.wto_num || 2}/>
                    </div>
                </div>

                <div className="row mt-4">
                    <div className={columns_div}>{__('Cost of a consultation')}:</div>
                    <div className={columns_div}>
                        <div className="input-group input-group-sm">
                            <input type="number" step="any" name="cost_consult" placeholder=".." required className="form-control"
                                readOnly={true}
                                defaultValue={currentInterview.costs_resources.cost_consult}/>
                            <div className="input-group-append"><span className="input-group-text">€</span></div>
                        </div>
                    </div>
                    {configType=='private'&&( <div className={columns_div}>
                        <div className="input-group input-group-sm mb-2">
                            <input type="number" step="any" name="consult_q" placeholder=".." className="form-control"
                                defaultValue={currentInterview.costs_resources.consult_q|| direct_costs_p.consult_co.cost}/>
                            <div className="input-group-append"><span className="input-group-text">€</span></div>
                        </div>
                    </div>)}
                </div>
                <hr/>
                <div className="row">
                    <div className="col row">
                        <div className="col-md-6 offset-md-6"><b>{__('Time (minutes)')}</b></div>
                        <div className="col-7">{__('Duration of a consultation')}</div>
                        <div className="col-5">
                            <div className="input-group input-group-sm">
                                <input type="number" name="duration_consultion" required className="form-control"
                                    defaultValue={currentInterview.costs_resources.duration_consultion || 15}/>
                                <div className="input-group-append"><span className="input-group-text">{__('min')}</span></div>
                            </div>
                        </div>
                        <div className="col-7">{__('Duration of a Nd: YAG laser')}</div>
                        <div className="col-5">
                            <div className="input-group input-group-sm">
                                <input type="number" name="duration_yag_laser" required className="form-control"
                                    defaultValue={currentInterview.costs_resources.duration_yag_laser || 15}/>
                                <div className="input-group-append"><span className="input-group-text">{__('min')}</span></div>
                            </div>
                        </div>
                        <div className="col-7">{__('Duration of cataract surgery')}</div>
                        <div className="col-5">
                            <div className="input-group input-group-sm">
                                <input type="number" name="duration_cataract" required className="form-control"
                                    defaultValue={currentInterview.costs_resources.duration_cataract || 30}/>
                                <div className="input-group-append"><span  className="input-group-text">{__('min')}</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="content mt-4">
                            <h5>{__('Split of saved time between cataract surgery & consultation')}</h5>
                            <div className="row mt-4">
                                <div className="col">
                                    <div className="input-group input-group-sm">
                                        <input max={100} min={0} className="form-control" type="number" value={splitTime.cataract} onChange={e=>{setSplitTimeCheck('cataract',e.target.value)}} />
                                        <div className="input-group-append"><span className="input-group-text">%</span></div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="input-group input-group-sm">
                                        <input max={100} min={0} className="form-control" type="number" value={splitTime.consult} onChange={e=>{setSplitTimeCheck('consult',e.target.value)}}/>
                                        <div className="input-group-append"><span className="input-group-text">%</span></div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col"><label>{__('Cataract surgery')}</label></div>
                                <div className="col text-right"><label>{__('Consultation')}</label></div>
                            </div>

                        </div>
                    </div>
                </div>
            </form>
            <br />
        </div>


        <div className="position-fixed p-1 text-right" style={{right:0,top:'50%'}}>

            <button type="submit" className="btn btn-primary" onClick={()=>{saveStep(); changeStep(1);}}>{__('next')+' >'} </button>
        </div>
        </>
    )
}

export default StepCost
